import React, { useEffect, useState } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { gql, useMutation } from "@apollo/client";
import { config } from "../configuration/settings";
import { Error } from "../../components/Error";
import { ErrorCollection } from "../../components/ErrorCollection";
import { Loading } from "../../components/Loading";
import { extractApolloErrorMessages } from "../graphql/extractApolloErrorMessages";
import { useAppDispatch } from "../../redux";
import { authSlice } from "../../redux/slices/authSlice";
import Cookies from "js-cookie";
import { SUPPORT_EMAIL, USER_IMPERSONATION_COOKIE_KEY } from "../../constants";
import { ImpersonationState } from "../../types/ImpersonationState";

const RAISE_USER_LOGGED_IN_EVENT_MUTATION = gql`
  mutation UpdateUserLoggedInStatus {
    updateUserLoggedInStatus {
      status
    }
  }
`;

const loginToEmbeddedApplicationInBackground = (
  id: number,
  key: string,
  host: string,
  loginUrl: string,
  logoutUrl: string,
  auth0OrganisationCode: string,
  customToken: string,
  dispatch,
  impersonationRequest: ImpersonationState,
) => {
  if (window.Worker) {
    const embeddedApplicationLoginWorker = new Worker(
      process.env.PUBLIC_URL + "/embeddedApplicationLoginWorker.js",
    );

    let encodedImpersonationRequest = undefined;

    if (impersonationRequest) {
      const impersonationRequestString: string =
        JSON.stringify(impersonationRequest);

      encodedImpersonationRequest = encodeURIComponent(
        impersonationRequestString,
      );
    }

    embeddedApplicationLoginWorker.postMessage([
      host,
      loginUrl,
      logoutUrl,
      auth0OrganisationCode,
      customToken,
      encodedImpersonationRequest,
    ]);

    embeddedApplicationLoginWorker.onmessage = function (e) {
      const result = e.data;
      if (result.length > 0) {
        if (result.length > 1 && result[1].length > 0) {
          dispatch(
            authSlice.actions.setEmbeddedApplicationAuthenticationState({
              id: id,
              key: key,
              isAuthenticated: result[0],
              authenticationError: result[1],
            }),
          );
        } else {
          dispatch(
            authSlice.actions.setEmbeddedApplicationAuthenticationState({
              id: id,
              key: key,
              isAuthenticated: result[0],
            }),
          );
        }
      }
    };
  }
};

const checkTokenClaims = (user: User): string[] => {
  const errorMessage = [];
  if (!user["https://c3posttrade.com/iamUserId"]) {
    errorMessage.push("iamUserId is null or undefined in the token claim");
  }
  if (!user["https://c3posttrade.com/activeIamUserId"]) {
    errorMessage.push(
      "activeIamUserId is null or undefined in the token claim",
    );
  }
  if (!user["https://c3posttrade.com/iamOrganisationId"]) {
    errorMessage.push(
      "iamOrganisationId is null or undefined in the token claim",
    );
  }
  if (!user["https://c3posttrade.com/activeIamOrganisationId"]) {
    errorMessage.push(
      "activeIamOrganisationId is null or undefined in the token claim",
    );
  }
  if (!user["https://c3posttrade.com/subscriptionId"]) {
    errorMessage.push("subscriptionId is null or undefined in the token claim");
  }

  return errorMessage;
};

export const WithAuthentication = ({ children }) => {
  const settings = config.get();

  const { isLoading, user, error, isAuthenticated } = useAuth0();
  const dispatch = useAppDispatch();
  const [tokenErrorMessages, setTokenErrorMessages] = useState<string[]>([]);
  const [isImpersonationError, setIsImpersonationError] = useState(false);

  const isOrgError =
    error &&
    (error.message ===
      "authorization request parameter organization must be an organization id" ||
      error.message.startsWith("parameter organization is invalid"));

  const impersonationRequestString =
    Cookies.get(USER_IMPERSONATION_COOKIE_KEY) ?? undefined;

  const impersonationRequest: ImpersonationState = impersonationRequestString
    ? JSON.parse(impersonationRequestString)
    : undefined;

  // Mutation to raise the user logged in event with IAM
  const [
    updateUserLoggedInStatus,
    {
      data: updateUserLoggedInStatusResponse,
      error: updateUserLoggedInStatusError,
    },
  ] = useMutation(RAISE_USER_LOGGED_IN_EVENT_MUTATION);

  // Post authentication actions
  useEffect(
    () => {
      if (isAuthenticated) {
        console.debug("Post authentication actions...");
        setTokenErrorMessages(checkTokenClaims(user));

        if (tokenErrorMessages.length === 0) {
          const isUserImpersonating =
            user["https://c3posttrade.com/iamUserId"] !==
            user["https://c3posttrade.com/activeIamUserId"];

          if (
            (impersonationRequest && !isUserImpersonating) ||
            (!impersonationRequest && isUserImpersonating)
          ) {
            // Remove cookie is it does not match the impersonation status i.e. impersonation failed so we could return to the original user after the next reload
            Cookies.remove(USER_IMPERSONATION_COOKIE_KEY, { path: "/" });
            setIsImpersonationError(true);
          } else {
            dispatch(
              authSlice.actions.setIsUserImpersonating(isUserImpersonating),
            );

            if (Array.isArray(settings.embeddedApplications)) {
              settings.embeddedApplications.forEach((embeddedApplication) => {
                loginToEmbeddedApplicationInBackground(
                  embeddedApplication.id,
                  embeddedApplication.key,
                  embeddedApplication.embeddedHost,
                  embeddedApplication.embeddedLoginUrl,
                  embeddedApplication.embeddedLogoutUrl,
                  user?.org_id,
                  user["https://c3posttrade.com/customToken"],
                  dispatch,
                  impersonationRequest,
                );
              });
            }

            // Raise user logged in event with IAM
            updateUserLoggedInStatus();

            // @ts-ignore - the rg4js script is dynamically loaded from a CDN at runtime
            if (typeof rg4js != "undefined" && rg4js) {
              // @ts-ignore - the rg4js script is dynamically loaded from a CDN at runtime
              rg4js("setUser", {
                identifier: user.email,
                isAnonymous: false,
                email: user.email,
                fullName: user.name,
              });
            }
          }
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, updateUserLoggedInStatus],
  );

  useEffect(() => {
    if (isOrgError) {
      dispatch(authSlice.actions.setUserOrganisationId(undefined));
    }
  }, [isOrgError, dispatch]);

  // If Auth0 is still loading, return...
  if (isLoading) {
    return <Loading width={75} height={75} />;
  }

  if (error && !isOrgError) {
    return (
      <Error
        header="There was a problem authenticating"
        errorMessage={error.message}
      />
    );
  }

  if (tokenErrorMessages.length > 0) {
    return (
      <ErrorCollection
        header="There was a problem with the access token"
        errorMessages={tokenErrorMessages}
      />
    );
  }

  if (isImpersonationError) {
    return (
      <Error
        header="There was a problem logging in to the client area"
        errorMessage={`The attempt to log in to client area was unsuccessful. Please try again later or contact ${SUPPORT_EMAIL} if this problem persists`}
        onClose={() => window.location.reload()}
      />
    );
  }

  // If the GraphQL API returns an error...
  if (updateUserLoggedInStatusError) {
    return (
      <ErrorCollection
        header="There was a problem updating the user status"
        errorMessages={extractApolloErrorMessages(
          updateUserLoggedInStatusError,
        )}
      />
    );
  }

  if (
    updateUserLoggedInStatusResponse?.updateUserLoggedInStatus &&
    updateUserLoggedInStatusResponse.updateUserLoggedInStatus.status !==
      "SUCCESS"
  ) {
    return (
      <ErrorCollection
        header="There was a problem updating the user status"
        errorMessages={[
          "The user status response code is: " +
            updateUserLoggedInStatusResponse.updateUserLoggedInStatus.status,
        ]}
      />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
