import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "../redux";
import { Selectors } from "../redux/selectors";
import { extractApolloErrorMessages } from "../utils/graphql/extractApolloErrorMessages";
import { ErrorBoundary } from "./ErrorBoundary";
import { ErrorCollection } from "./ErrorCollection";
import { Grid } from "./Grid";
import { Loading } from "./Loading";
import { RefObject } from "react";

// displayName is a local field created in the typePolicies
export const NOTES_HISTORY_QUERY = gql`
  query GetNotesHistory($entityUri: ID!, $activeIamOrganisationId: ID!) {
    notes(entityUri: $entityUri) {
      id
      noteText
      author {
        id
        firstName
        lastName
        organisation {
          id
          name
        }
        displayName(activeIamOrganisationId: $activeIamOrganisationId) @client
      }
      whenNoteCreated
    }
  }
`;

function Notes({
  gridRef,
  selectedRowId,
  gridContainerStyle,
}: {
  selectedRowId: string;
  gridRef?: RefObject<any>;
  gridContainerStyle?: React.CSSProperties;
}) {
  const { user } = useAuth0();
  const { data, error, loading, refetch } = useQuery(NOTES_HISTORY_QUERY, {
    variables: {
      entityUri: selectedRowId,
      activeIamOrganisationId:
        user["https://c3posttrade.com/activeIamOrganisationId"],
    },
    fetchPolicy: "cache-and-network",
    skip: !user,
  });

  return (
    <ErrorBoundary>
      <div className="lower-grid">
        {loading && !data && <Loading width={75} height={75} />}
        {error && (
          <ErrorCollection
            header="There was a problem fetching the data"
            errorMessages={extractApolloErrorMessages(error)}
          />
        )}
        {data && (
          <Grid
            gridRef={gridRef}
            type="Notes"
            data={data.notes}
            onRefreshClicked={refetch}
            userId={user?.sub}
            gridOptions={{
              suppressRowClickSelection: true,
            }}
            containerStyle={{ height: "100%", ...gridContainerStyle }}
          />
        )}
      </div>
    </ErrorBoundary>
  );
}

export function NoteHistoryTab({
  gridRef,
  gridContainerStyle,
}: {
  gridRef?: RefObject<any>;
  gridContainerStyle?: React.CSSProperties;
}) {
  const gridSelectedRows = useAppSelector((state) =>
    Selectors.gridSelectedRows("FailsSummary", state),
  );
  const isNoRowSelected = gridSelectedRows.length === 0;
  const areMultipleRowsSelected = gridSelectedRows.length > 1;

  if (isNoRowSelected) {
    return (
      <p className="grid-lower-tabs-inline-alert">
        Please select a record to view its note history
      </p>
    );
  }

  if (areMultipleRowsSelected) {
    return (
      <p className="grid-lower-tabs-inline-alert">
        You selected {gridSelectedRows.length} records, please select a record
        to view its notes history
      </p>
    );
  }

  return (
    <Notes
      gridRef={gridRef}
      selectedRowId={gridSelectedRows[0].id}
      gridContainerStyle={gridContainerStyle}
    />
  );
}
