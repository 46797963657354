import { User } from "@auth0/auth0-react";
import { NavigateFunction } from "react-router-dom";
import { MenuItem } from "../../types/MenuItem";
import { AppSettings } from "../configuration/settings";
import { checkUserHasPermission } from "../auth/checkUserHasPermission";
import { constructUrlFromMenuItem } from "./constructUrlFromMenuItem";

export const navigateToFirstEnabledMenuItemRoute = (
  menu: MenuItem[],
  user: User,
  navigate: NavigateFunction,
  settings: AppSettings,
) => {
  // TODO...support multiple levels of menu
  if (navigate) {
    for (const menuItem of menu) {
      if (
        checkUserHasPermission(user, menuItem.requiredPermission) &&
        menuItem.items
      ) {
        for (const subMenuItem of menuItem.items) {
          if (checkUserHasPermission(user, subMenuItem.requiredPermission)) {
            const url = constructUrlFromMenuItem(
              subMenuItem,
              menuItem,
              settings,
            );
            navigate(url);
            return;
          }
        }
      }
    }
  }
};
