import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateObj } from "../utils";

interface GridStateProperties {
  isGridReady: boolean;
  selectedRows: Array<any>;
  editorModalMode: "edit" | "add-note";
}

interface GridState {
  type: string;
  state: GridStateProperties;
}

export const initialGridStateProperties: GridStateProperties = {
  isGridReady: false,
  selectedRows: [],
  editorModalMode: "edit",
};

export const gridSlice = createSlice({
  name: "gridSlice",
  initialState: {},
  reducers: {
    setState(state, action: PayloadAction<Partial<GridState>>) {
      let gridProperties: GridStateProperties;

      if (state[action.payload.type]) {
        gridProperties = { ...state[action.payload.type] };
      } else {
        gridProperties = {
          isGridReady: false,
          selectedRows: [],
          editorModalMode: "edit",
        };
      }

      updateObj(gridProperties, action.payload.state);
      state[action.payload.type] = gridProperties;
    },
  },
});
