import React from "react";
import { Error } from "./Error";

interface ErrorCollectionProps {
  header: string;
  errorMessages: string[];
  onClose?: () => void;
}

export const ErrorCollection = (props: ErrorCollectionProps): JSX.Element => {
  const errorMessage = (
    <React.Fragment>
      <p>The following errors occurred:</p>
      <ul>
        {props.errorMessages.map((errorMessage, index) => {
          return <li key={index}>{errorMessage}</li>;
        })}
      </ul>
    </React.Fragment>
  );

  return (
    <Error
      header={props.header}
      errorMessage={errorMessage}
      onClose={props.onClose}
    />
  );
};
