import { User } from "@auth0/auth0-react";

export const checkUserHasPermission = (
  user: User,
  permission: string,
): boolean => {
  if (user) {
    let permissions: string[] = [];
    // Check if the user has the required permission
    if (user["https://c3posttrade.com/permissions"]) {
      permissions = user["https://c3posttrade.com/permissions"];
    }

    // if permission is undefined or blank then no check required
    if (!permission || permission.length === 0) {
      return true;
    } else {
      return (
        Array.isArray(permissions) &&
        permissions.filter(
          (value) =>
            value.toLocaleLowerCase() === permission.toLocaleLowerCase(),
        ).length > 0
      );
    }
  } else {
    return false;
  }
};
