import type { ReactNode } from "react";
import { Form, Alert } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export interface FormFieldBoxBaseProps {
  id: string;
  label: string;
}

export function FormFieldBox({
  id,
  label,
  children,
}: FormFieldBoxBaseProps & {
  children: ReactNode;
}) {
  const { formState } = useFormContext();

  const fieldErrorMessage = formState.errors?.[id]?.message;
  const hasErrorMessage = !!fieldErrorMessage;

  return (
    <Form.Group className="mb-3" id={id}>
      <Form.Label className="form-label" htmlFor={id}>
        {label}
      </Form.Label>
      {children}
      {hasErrorMessage && (
        <Alert
          className="form-error"
          variant="danger"
          style={{ marginTop: "4px" }}
        >
          {fieldErrorMessage}
        </Alert>
      )}
    </Form.Group>
  );
}
