import { ReactNode, useCallback, useState } from "react";
import { Modal as BSModal, ModalProps } from "react-bootstrap";
import Draggable from "react-draggable";
import ModalDialog from "react-bootstrap/ModalDialog";
import "./ModalBase.scss";

export interface ModalBaseProps extends Pick<ModalProps, "className"> {
  isOpen: boolean;
  closeModal: () => void;
}

const DragableWrapper = (props: any) => (
  <Draggable>
    <ModalDialog {...props} />
  </Draggable>
);

export function ModalBase({
  isOpen,
  closeModal,
  className,
  children,
}: ModalBaseProps & {
  children: ReactNode;
}) {
  return (
    <BSModal
      dialogAs={DragableWrapper}
      animation
      show={isOpen}
      centered
      onHide={closeModal}
      className={className}
    >
      {children}
    </BSModal>
  );
}

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return { isOpen, openModal, closeModal };
}
