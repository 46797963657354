import { CustomTooltipComponent } from "./gridComponents.types";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "react-bootstrap";
import { formatDateTime } from "../../../utils/format/formatDateTime";
import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";

interface LatestNoteDateType {
  latestNote: {
    id: string;
    noteText: string;
    author: {
      id: string;
    };
    whenNoteCreated: string; // "2021-09-28T17:00:00.000Z";
  };
}

// displayName is a local field created in the typePolicies
export const GET_NOTE_AUTHOR = gql`
  query GetNoteAuthor($noteId: ID!, $activeIamOrganisationId: ID!) {
    note(id: $noteId) {
      author {
        id
        firstName
        lastName
        organisation {
          id
          name
        }
        displayName(activeIamOrganisationId: $activeIamOrganisationId) @client
      }
    }
  }
`;

const TooltipContent = ({
  authorName,
  whenNoteCreated,
  noteText,
  isLoading,
}: {
  authorName?: string;
  whenNoteCreated: string;
  noteText: string;
  isLoading?: boolean;
}) => {
  const whenNoteCreatedFormattedString = formatDateTime(whenNoteCreated);

  if (isLoading) {
    return (
      <span>
        [<Spinner animation="border" size="sm" data-testid="spinner" />,{" "}
        {whenNoteCreatedFormattedString}]: {noteText}
      </span>
    );
  }

  if (authorName?.length > 0 && whenNoteCreatedFormattedString?.length > 0) {
    return (
      <span>{`[${authorName}, ${whenNoteCreatedFormattedString}]: ${noteText}`}</span>
    );
  } else {
    return <span>{`${noteText}`}</span>;
  }
};

export const LatestNoteTooltipComponent: CustomTooltipComponent<
  LatestNoteDateType
> = (props) => {
  const { id, author, noteText, whenNoteCreated } = props.data.latestNote;

  const client = useApolloClient();
  const { user } = useAuth0();

  const cacheId = "User:" + author.id;

  const cachedUser = client.readFragment({
    id: cacheId,
    fragment: gql`
      fragment MyUser on User {
        id
        firstName
        lastName
        organisation {
          id
          name
        }
        displayName(activeIamOrganisationId: $activeIamOrganisationId)
      }
    `,
    variables: {
      activeIamOrganisationId:
        user["https://c3posttrade.com/activeIamOrganisationId"],
    },
  });

  const { data, error, loading } = useQuery(GET_NOTE_AUTHOR, {
    variables: {
      noteId: id,
      activeIamOrganisationId:
        user["https://c3posttrade.com/activeIamOrganisationId"],
    },
    fetchPolicy: "cache-first",
    skip: cachedUser || !user,
  });

  if (loading || error || (!data && !cachedUser) || !user) {
    return (
      <TooltipContent
        authorName={null}
        whenNoteCreated={whenNoteCreated}
        noteText={noteText}
        isLoading={loading}
      />
    );
  }

  return (
    <>
      {(data || cachedUser) && (
        <TooltipContent
          authorName={data?.note.author.displayName ?? cachedUser.displayName}
          whenNoteCreated={whenNoteCreated}
          noteText={noteText}
        />
      )}
    </>
  );
};
