import React from "react";

type CustomToggleProps = {
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

// TODO...resolve these TS issues...
export const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      className="text-reset text-decoration-none"
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </a>
  ),
);
