import { AuthorizedApolloProvider } from "../../../utils/auth/AuthorizedApolloProvider";
import type { CustomTooltipComponent } from "./gridComponents.types";
import { LatestNoteTooltipComponent } from "./LatestNoteTooltipComponent";

const TOOLTIP_COMPONENT_MAP: Record<string, CustomTooltipComponent> = {
  latestNote: LatestNoteTooltipComponent,
} as const;

const TOOLTIP_STYLE: React.CSSProperties = {
  backgroundColor: "#ececec",
  border: "1px solid #808080",
  padding: "8px",
  overflow: "hidden",
  position: "absolute",
  color: "#6A6A6A",
  width: "160px",
} as const;

/**
 * This is a React tooltip component for ag-grid.
 * It behaves like a proxy, you give it `type` key, it will render using related React component,
 * This is for solving the case where we might need different components for different tooltips in the future.
 *
 * You can assign this tooltip component to different column as long as the `type` is different
 *
 * for example, if you link the React component A to the key B,
 * then any field with a colDef { tooltipComponentParams: { type: B } } will be rendered with React component A
 *
 * How to use:
 *
 * 1. create your tooltip React component like `const LatestNoteTooltipComponent: CustomTooltipComponent = (props) => null`
 * 2. add the key,component pair to `TOOLTIP_COMPONENT_MAP` like { latestNote: LatestNoteTooltipComponent }
 * 3. In the colDef, pass the above key to determine which component to use, for example, { tooltipComponentParams: { type: "latestNote" } }
 * 4. enjoy :)
 *
 * if you want define the type for the props.data pass it like CustomTooltipComponent<MyDataType>
 *
 */
export const GridCellTooltip: CustomTooltipComponent = (props) => {
  const tooltipType = props.type;

  const isTypeNotExist = !(tooltipType in TOOLTIP_COMPONENT_MAP);

  if (isTypeNotExist) {
    console.warn(`tooltip type ${tooltipType} does not exist!`);
    return null;
  }

  const Component = TOOLTIP_COMPONENT_MAP[tooltipType];

  return (
    <div style={TOOLTIP_STYLE}>
      <AuthorizedApolloProvider>
        <Component {...props} />
      </AuthorizedApolloProvider>
    </div>
  );
};
