import { useContext } from "react";
import { NavigationContext } from "../context/NavigationContext";

export const Breadcrumb = (): JSX.Element => {
  const navigationState = useContext(NavigationContext);

  return (
    <>
      {navigationState.crumbs?.map((item, key) =>
        key + 1 === navigationState.crumbs.length ? (
          <span key={`breadcrumb-${key}`}>{item}</span>
        ) : (
          <span key={`breadcrumb-${key}`}>
            {item}
            <i className="bi-caret-right ps-2 pe-2"></i>
          </span>
        ),
      )}
    </>
  );
};
