import { useQuery, gql, QueryResult, OperationVariables } from "@apollo/client";

export const GET_CONFIGURATION_QUERY = gql`
  query Namespace($namespace: ID!, $configKey: String) {
    namespace(namespace: $namespace) {
      name
      status
      deleted
      configuration(configKey: $configKey) {
        key
        value
      }
    }
  }
`;

export const GET_TRUSTED_RELATIONSHIPS = gql`
  query getTrustedRelationships($organisationId: ID!) {
    organisation(id: $organisationId) {
      status
      messages
      organisation {
        trustedRelationships {
          opposingOrganisation {
            name
            id
          }
          id
        }
      }
    }
  }
`;

export function GetGridConfig(
  namespace: string,
  type: string,
): QueryResult<any, OperationVariables> {
  return useQuery(GET_CONFIGURATION_QUERY, {
    variables: {
      namespace: namespace,
      configKey: "ui-grid-" + type.toLowerCase(),
    },
  });
}
