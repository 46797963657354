import { CellClassParams, CellStyleFunc } from "ag-grid-community";
import dayjs from "dayjs";

export const agedDateCellStyle: CellStyleFunc = (params: CellClassParams) => {
  if (params?.value == null) {
    return { backgroundColor: "", textAlign: "center" };
  }

  const dateValue = dayjs(params.value);

  if (dateValue.isValid()) {
    const referenceDateToday = dayjs().startOf("day");
    const referenceDateYesterday = referenceDateToday.subtract(1, "day");

    if (dateValue >= referenceDateToday) {
      return { backgroundColor: "#99FF99", textAlign: "center" };
    } else if (dateValue < referenceDateYesterday) {
      return { backgroundColor: "#99FFFF", textAlign: "center" };
    } else {
      return { backgroundColor: "#FFFF99", textAlign: "center" };
    }
  } else {
    return { backgroundColor: "", textAlign: "center" };
  }
};
