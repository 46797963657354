import { ApolloClient, gql } from "@apollo/client";

export type FieldUpdate = {
  name: string;
  value: any;
};

// Updates the local cached FailsRecord (in the Apollo client cache) with the current recordUpdateState and any field updates
export const updateFailsRecord = (
  apolloClient: ApolloClient<any>,
  recordId: string,
  recordUpdateState: string,
  fieldUpdates: FieldUpdate[],
  noteText: string,
): void => {
  const recordUpdate = {
    __typename: "FailsRecord",
    id: recordId,
    recordUpdateState: recordUpdateState,
  };

  fieldUpdates.forEach((field) => {
    recordUpdate[field.name] = field.value;
  });

  // There is currently an issue causing exceptions to be thrown when noteText.length is 0 and recordUpdate do not contain a latestNote property.
  // We have confirmed the cache update works as intended so don't worry.
  // This is a bug in the Apollo library, please see https://github.com/apollographql/apollo-client/issues/10284
  if (noteText?.length > 0) {
    recordUpdate["latestNote"] = {
      id: null,
      author: {
        id: null,
      },
      noteText: noteText,
      whenNoteCreated: null,
    };
  }

  apolloClient.writeQuery({
    query: gql`
      query WriteFailsRecord($id: ID!) {
        writeFailsRecord(id: $id) {
          id
          recordUpdateState
          ${fieldUpdates.map((x) => x.name).join("\r\n")}
          latestNote
        }
      }
    `,
    data: {
      writeFailsRecord: recordUpdate,
    },
    variables: {
      id: recordId,
    },
    overwrite: true,
  });
};
