import { useAppSelector } from "../redux";
import { Selectors } from "../redux/selectors";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import "./ImpersonationBanner.scss";

export const ImpersonationBanner = ({
  organisationName,
}: {
  organisationName: string;
}): JSX.Element => {
  const isUserImpersonating = useAppSelector((state) =>
    Selectors.isUserImpersonating(state),
  );

  return (
    <>
      {isUserImpersonating && (
        <Row className="impersonation-banner">
          <Col className="col-12">
            <div>Logged in as client: {organisationName}</div>
          </Col>
        </Row>
      )}
    </>
  );
};
