import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CustomToggle } from "./CustomToggle";
import { useModal } from "./ModalBase";
import {
  LoginAsClientModal,
  TrustedRelationshipItem,
} from "./LoginAsClientModal";
import { useAppSelector } from "../redux";
import { Selectors } from "../redux/selectors";

interface UserProfileMenuProps {
  /**
   * Click handler for the logout menu item
   */
  logout: (logoutEntirely: boolean) => boolean;
  trustedRelationships: TrustedRelationshipItem[];
}

export const UserProfileMenu = ({
  logout,
  trustedRelationships,
}: UserProfileMenuProps): JSX.Element => {
  const [openedDropdown, setOpenedDropdown] = useState<boolean>(false);

  const showDropdown = () => {
    setOpenedDropdown(true);
  };

  const hideDropdown = () => {
    setOpenedDropdown(false);
  };

  const { isOpen, openModal, closeModal } = useModal();

  const isUserImpersonating = useAppSelector((state) =>
    Selectors.isUserImpersonating(state),
  );

  return (
    <>
      <Dropdown
        title="User Profile"
        show={openedDropdown}
        onMouseEnter={(e) => {
          showDropdown();
        }}
        onMouseLeave={(e) => hideDropdown()}
      >
        <Dropdown.Toggle as={CustomToggle}>
          <i className="bi-person fs-2"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {trustedRelationships?.length > 0 && (
            <Dropdown.Item
              onClick={() => {
                openModal();
              }}
            >
              Log in As Client...
            </Dropdown.Item>
          )}
          {isUserImpersonating && (
            <Dropdown.Item
              onClick={() => {
                return logout(false);
              }}
            >
              Log out of Client Area
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => {
              return logout(true);
            }}
          >
            Log out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {isOpen && (
        <LoginAsClientModal
          trustedRelationships={trustedRelationships}
          isOpen={isOpen}
          closeModal={closeModal}
        />
      )}
    </>
  );
};
