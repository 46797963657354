import { gql } from "@apollo/client";

export const CASH_MARGIN_SUMMARY_QUERY = gql`
  query GetCashMarginSummary($recordSetId: ID!) {
    cashMarginSummary(recordSetId: $recordSetId) {
      id
      whenLastUpdated
      records {
        id
        tenant
        localEntity
        counterparty
        counterpartyEntity
        accountType
        accountNumber
        accountName
        currency
        baseCurrency
        fxRate
        marginType
        recordDescription
        marginRequirement
        equity
        previousDayMarginRequirement
        previousDayEquity
        whenRecordReceived
        concurrencyStamp
      }
    }
  }
`;

export const CASH_MARGIN_SUMMARY_RECORD_SET_QUERY = gql`
  query CashMarginSummary($recordSetId: ID!) {
    cashMarginSummary(recordSetId: $recordSetId) {
      id
      whenLastUpdated
    }
  }
`;
