import { Provider } from "react-redux";
import { store } from "../redux";
import { Auth0ProviderWithHistory } from "./auth/Auth0ProviderWithHistory";
import { AuthorizedApolloProvider } from "./auth/AuthorizedApolloProvider";

export function Providers({ children }: { children: any }) {
  return (
    <Provider store={store}>
      <Auth0ProviderWithHistory>
        <AuthorizedApolloProvider>{children}</AuthorizedApolloProvider>
      </Auth0ProviderWithHistory>
    </Provider>
  );
}
