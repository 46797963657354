import loading from "../assets/images/c3-loading.svg";

interface LoadingProps {
  width: number;
  height: number;
}

export const Loading = (props: LoadingProps): JSX.Element => {
  return (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <img
        src={loading}
        alt="Loading..."
        width={props.width}
        height={props.height}
      />
    </div>
  );
};
