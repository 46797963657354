import ReactBootstrapButton from "react-bootstrap/Button";

interface ButtonProps {
  type?: "submit" | "reset" | "button";
  /**
   * Button text
   */
  text: string;
  /**
   * Is the button disabled?
   */
  disabled?: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;

  className?: string;

  variant?: "primary" | "outline-secondary";
}

export const Button = (props: ButtonProps): JSX.Element => {
  return (
    <ReactBootstrapButton {...props} size="sm">
      {props.text}
    </ReactBootstrapButton>
  );
};
