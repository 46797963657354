import { useContext, useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux";
import { Selectors } from "../redux/selectors";
import { Error } from "../components/Error";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { Loading } from "../components/Loading";
import { NavigationContext } from "../context/NavigationContext";
import { config } from "../utils/configuration/settings";
import { findEmbeddedApplication } from "../utils/configuration/findEmbeddedApplication";
import { appendQueryStringParameterToRelativePath } from "../utils/menu/appendQueryStringParameterToRelativePath";
import { constructUrlFromMenuItem } from "../utils/menu/constructUrlFromMenuItem";
import { findMenuItemFromExternalPath } from "../utils/menu/findMenuItemFromExternalPath";

export const EmbeddedPage = () => {
  const settings = config.get();

  const navigationState = useContext(NavigationContext);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [src, setSrc] = useState<string>("");

  const isUserAuthenticatedWithEmbeddedApplication = useAppSelector((state) =>
    Selectors.isUserAuthenticatedWithEmbeddedApplication(
      state,
      Number(searchParams.get("app")),
    ),
  );

  const embeddedApplicationAuthenticationError = useAppSelector((state) =>
    Selectors.embeddedApplicationAuthenticationError(
      state,
      Number(searchParams.get("app")),
    ),
  );

  const embeddedApplicationSettings =
    Array.isArray(settings.embeddedApplications) &&
    settings.embeddedApplications.find(
      (x) => x.id.toString() === searchParams.get("app"),
    );

  useEffect(() => {
    const handleMessage = (event) => {
      console.debug(
        `Message received ${JSON.stringify(event.data)} from origin ${
          event.origin
        }`,
      );

      // Check that event.origin matches an embedded host (and get the host details)
      const embeddedApplication = findEmbeddedApplication(
        event.origin,
        settings,
      );

      if (embeddedApplication) {
        console.debug(`Embedded application found: ${embeddedApplication.key}`);

        // Iterate through the menu to locate a  menu item where the external path matches the event data
        const [menuItem, parentMenuItem] = findMenuItemFromExternalPath(
          navigationState.menu,
          event.data.replace(embeddedApplication.embeddedHost, ""),
        );
        if (menuItem) {
          console.debug(`Menu item found: ${menuItem.text}`);

          // If the current location does not match the route of the matching menu item
          if (
            location.pathname !==
            `/embed${parentMenuItem?.route}${menuItem.route}`
          ) {
            // Navigate to the route of the matching menu item (with the embedded parameters)
            navigate(
              constructUrlFromMenuItem(menuItem, parentMenuItem, settings) +
                "&resync=true",
            );
          }
        }
      }
    };

    // Add an event handler for window messages from the embedded window
    window.addEventListener("message", handleMessage);

    // Cleanup when this component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [location.pathname, navigate, navigationState.menu, settings]);

  useEffect(() => {
    console.log("Embedded page loaded with src=");
  }, []);

  useEffect(() => {
    if (!searchParams.get("resync")) {
      setSrc(
        searchParams.get("path")
          ? appendQueryStringParameterToRelativePath(
              searchParams.get("path"),
              "timestamp",
              Date.now().toString(),
            )
          : "",
      );
    }
  }, [searchParams]);

  return (
    <ErrorBoundary>
      <div className="main-body">
        {!embeddedApplicationSettings && (
          <>
            <h1>Embedded page not supported on this environment</h1>
            <h2>Params:</h2>
            <p>Embedded Application ID: {searchParams.get("app")}</p>
            <p>Embedded Path: {searchParams.get("path")}</p>
          </>
        )}
        {embeddedApplicationSettings &&
          !isUserAuthenticatedWithEmbeddedApplication &&
          !embeddedApplicationAuthenticationError && (
            <Loading width={75} height={75} />
          )}
        {embeddedApplicationSettings &&
          embeddedApplicationAuthenticationError?.length > 0 && (
            <Error
              errorMessage={embeddedApplicationAuthenticationError}
              header={"The connection has timed out"}
            />
          )}
        {embeddedApplicationSettings &&
          isUserAuthenticatedWithEmbeddedApplication && (
            <iframe
              title="embedded-application"
              src={
                embeddedApplicationSettings.embeddedHost +
                (src?.length > 0
                  ? src
                  : appendQueryStringParameterToRelativePath(
                      searchParams.get("path"),
                      "timestamp",
                      Date.now().toString(),
                    ))
              }
            ></iframe>
          )}
      </div>
    </ErrorBoundary>
  );
};
