import { integerFormatter } from "../valueFormatters/integerFormatter";
import { numberCellStyle } from "../cellStyles/numberCellStyle";

export const integerAggregatedColumn = {
  type: "rightAligned",
  aggFunc: "sum",
  enableValue: true,
  valueFormatter: integerFormatter,
  cellStyle: numberCellStyle,
  filter: "agNumberColumnFilter",
  filterParams: {
    buttons: ["reset"],
    inRangeInclusive: true,
  },
};
