import { useEffect } from "react";
import { useSelector } from "react-redux";
import { APP_STATE_STORAGE_KEY } from "../../constants";
import { AppState } from "../../redux";

const auth = (state: AppState) => state.auth;

/**  Subscribe to user state changes to sync it to local storage. */
export function usePersistAuthInfo() {
  const { user } = useSelector(auth);
  const isUserEmailProvided = !!user.email;

  useEffect(() => {
    if (!isUserEmailProvided) return;

    const stateToPersist = {
      auth: { user },
    };

    localStorage.setItem(APP_STATE_STORAGE_KEY, JSON.stringify(stateToPersist));
  }, [isUserEmailProvided, user]);
}
