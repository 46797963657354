import { dateTimeFormatter } from "../valueFormatters/dateTimeFormatter";
import { dateTimeSortComparator } from "../comparators/dateTimeSortComparator";
import { dateTimeFilterComparator } from "../comparators/dateTimeFilterComparator";

export const dateTimeColumn = {
  valueFormatter: dateTimeFormatter,
  cellStyle: { textAlign: "center" },
  comparator: dateTimeSortComparator,
  filter: "agDateColumnFilter",
  filterParams: {
    buttons: ["reset"],
    comparator: dateTimeFilterComparator,
    inRangeInclusive: true,
  },
};
