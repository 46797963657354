import { AppSettings, EmbeddedApplication } from "./settings";

export const findEmbeddedApplication = (
  host: string,
  settings: AppSettings,
): EmbeddedApplication => {
  let embeddedApplication: EmbeddedApplication;

  if (settings?.embeddedApplications) {
    embeddedApplication = settings.embeddedApplications.find(
      (app) => app.embeddedHost === host,
    );
  }

  return embeddedApplication;
};
