/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component } from "react";
import { Error } from "./Error";

interface ErrorProps {
  children: JSX.Element[] | JSX.Element;
}

type ErrorState = {
  errorMessage: string;
};

export class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { errorMessage: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { errorMessage: error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.errorMessage?.length > 0) {
      // You can render any custom fallback UI
      return <Error header="" errorMessage={this.state.errorMessage} />;
    }

    return this.props.children;
  }
}
