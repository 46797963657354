import { dateFormatter } from "../valueFormatters/dateFormatter";
import { dateTimeSortComparator } from "../comparators/dateTimeSortComparator";
import { dateTimeFilterComparator } from "../comparators/dateTimeFilterComparator";

export const dateColumn = {
  valueFormatter: dateFormatter,
  cellStyle: { textAlign: "center" },
  comparator: dateTimeSortComparator,
  filter: "agDateColumnFilter",
  filterParams: {
    buttons: ["reset"],
    comparator: dateTimeFilterComparator,
    inRangeInclusive: true,
  },
};
