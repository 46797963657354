import { dateTimeFormatter } from "../valueFormatters/dateTimeFormatter";
import { agedDateCellStyle } from "../cellStyles/agedDateCellStyle";
import { dateTimeSortComparator } from "../comparators/dateTimeSortComparator";
import { dateTimeFilterComparator } from "../comparators/dateTimeFilterComparator";

export const agedDateTimeColumn = {
  valueFormatter: dateTimeFormatter,
  cellStyle: agedDateCellStyle,
  comparator: dateTimeSortComparator,
  filter: "agDateColumnFilter",
  filterParams: {
    buttons: ["reset"],
    comparator: dateTimeFilterComparator,
    inRangeInclusive: true,
  },
};
