import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@apollo/client";
import { config } from "../utils/configuration/settings";
import { GET_CONFIGURATION_QUERY } from "../graphql";
import { navigateToFirstEnabledMenuItemRoute } from "../utils/menu/navigateToFirstEnabledMenuItemRoute";
import { useAppSelector } from "../redux";
import { Selectors } from "../redux/selectors";

export const Home = (): JSX.Element => {
  // Get settings...TODO, the settings should be passed down as React context
  const settings = config.get();

  const navigate = useNavigate();

  const { user, isLoading: isLoadingAuth0, isAuthenticated } = useAuth0();

  const { data: configData, loading: isLoadingConfig } = useQuery(
    GET_CONFIGURATION_QUERY,
    {
      variables: {
        namespace: settings.namespace,
        configKey: "ui-navigation",
      },
      skip: !isAuthenticated,
    },
  );

  const isUserImpersonating = useAppSelector((state) =>
    Selectors.isUserImpersonating(state),
  );

  React.useEffect(() => {
    if (isLoadingAuth0) return;

    if (!isAuthenticated) {
      navigate("/login");
    } else {
      if (isUserImpersonating !== undefined) {
        // Get the last accessed route from local storage
        const lastRoute = localStorage.getItem("currentRoute");

        if (
          !isUserImpersonating &&
          lastRoute &&
          lastRoute !== "/" &&
          lastRoute !== "/login"
        ) {
          navigate(lastRoute);
        } else {
          // Navigate to the first enabled menu item
          if (isLoadingConfig) {
            return;
          }

          if (
            configData?.namespace?.configuration &&
            configData.namespace.configuration.length > 1
          ) {
            console.log(
              `Multiple grid configurations returned for application: ${settings.applicationName} and grid type: ui-navigation, defaulting to the first one`,
            );
          }

          if (
            configData?.namespace?.configuration?.[0]?.value?.configuration
              ?.menu
          ) {
            navigateToFirstEnabledMenuItemRoute(
              configData.namespace.configuration?.[0].value.configuration.menu,
              user,
              navigate,
              settings,
            );
          }
        }
      }
    }
  }, [
    navigate,
    isLoadingAuth0,
    isAuthenticated,
    user,
    isLoadingConfig,
    configData,
    isUserImpersonating,
    settings,
  ]);

  return null;
};
