import { useGridApis } from "../../components/GridApiContext";
import { ColDef } from "ag-grid-community";

export function useColumnDefsForSelectedRows() {
  const { gridApi } = useGridApis();

  const columnDefsForEditingRow = gridApi
    .getColumnDefs()
    .filter((col: ColDef) => !col.hide && col.editable);

  return columnDefsForEditingRow;
}
