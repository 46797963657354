import { FormFieldBox, FormFieldBoxBaseProps } from "./FormFieldBox";
import { Form } from "react-bootstrap";
import { useFormContext, RegisterOptions } from "react-hook-form";

interface FormTextareaFieldProps extends FormFieldBoxBaseProps {
  defaultValue: string;
  validate?: RegisterOptions["validate"];
}

export function FormTextareaField({
  id,
  label,
  defaultValue,
  validate,
}: FormTextareaFieldProps) {
  const { register } = useFormContext();

  return (
    <FormFieldBox id={id} label={label}>
      <Form.Control
        id={id}
        as="textarea"
        defaultValue={defaultValue}
        {...register(id, { validate })}
      />
    </FormFieldBox>
  );
}
