import { useAuth0 } from "@auth0/auth0-react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  Operation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Error } from "../../components/Error";
import { API_URI, USER_IMPERSONATION_COOKIE_KEY } from "../../constants";
import { typePolicies } from "../../typePolicies";
import Cookies from "js-cookie";

const getApiEndpoint = (operation: Operation) => {
  const context = operation.getContext();

  if (context.isPublicQuery) {
    return API_URI.replace("/graphql", "/public/graphql");
  } else {
    return API_URI;
  }
};

const httpLink = createHttpLink({
  uri: getApiEndpoint,
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      console.error(`[GraphQL error]: ${message}`);
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError.message}`);
  }
});

const cache = new InMemoryCache({
  typePolicies: typePolicies,
});

export const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const { error, getAccessTokenSilently } = useAuth0();

  const impersonationRequest =
    Cookies.get(USER_IMPERSONATION_COOKIE_KEY) ?? undefined;

  const authLink = setContext(async (_request, previousContext) => {
    if (!previousContext.isPublicQuery) {
      const token = await getAccessTokenSilently({
        impersonationRequest,
      });

      if (token) {
        return {
          ...previousContext,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }
    }
  });

  const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache,
    connectToDevTools: true,
  });

  if (error) {
    return (
      <Error
        header="There was a problem authenticating"
        errorMessage={error.message}
      />
    );
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
