import React, { useEffect, useState } from "react";
import { User, useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { checkUserHasPermission } from "./checkUserHasPermission";
import { useQuery } from "@apollo/client";
import { GET_CONFIGURATION_QUERY } from "../../graphql";
import { AppSettings } from "../configuration/settings";
import { MenuItem } from "../../types/MenuItem";
import { useLocation } from "react-router-dom";

const checkUserIsAuthorisedForRoute = (
  menu: MenuItem[],
  user: User,
  routes: string[], // ["/embed","/trades","/csdr"]
): boolean => {
  let route = routes.shift(); // "/embed"

  if (route === "/embed") {
    route = routes.shift();
  }

  if (route) {
    const matchingConfiguration = menu?.filter((_) => _.route === route)[0];

    if (matchingConfiguration) {
      const hasPermission =
        matchingConfiguration.requiredPermission &&
        checkUserHasPermission(user, matchingConfiguration.requiredPermission);

      // If current menu level has a requiredPermission, and the user does not have the permission, then reject
      if (matchingConfiguration.requiredPermission && !hasPermission) {
        return false;
      }

      // If current menu level has NO requiredPermission OR
      // if current menu level has a requiredPermission, and the user has that permission
      if (!matchingConfiguration.requiredPermission || hasPermission) {
        if (routes.length > 0) {
          // check the next level down for the sub-route by recursively calling this function one level deeper
          return checkUserIsAuthorisedForRoute(
            matchingConfiguration.items,
            user,
            routes,
          );
          // Reached lowest level so succeed
        } else {
          return true;
        }
      }

      // safety net - should never reach here
      return false;

      // could not find matching configuration so deny access
    } else {
      return false;
    }
    // safety net - should never reach here
  } else {
    return false;
  }
};

export const RequireAuth = ({
  component,
  settings,
}: React.PropsWithChildren<{
  component: any;
  settings: AppSettings;
}>): JSX.Element => {
  const [routeIsAuthorised, setRouteIsAuthorised] = useState(false);

  const { isLoading, user, isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  const Component = component;

  const { data: configData, loading: isLoadingUiConfig } = useQuery(
    GET_CONFIGURATION_QUERY,
    {
      variables: {
        namespace: settings.namespace,
        configKey: "ui-navigation",
      },
      skip: !isAuthenticated,
    },
  );

  const location = useLocation();

  useEffect(() => {
    if (!isLoading && !isLoadingUiConfig) {
      if (isAuthenticated) {
        const pathnames = location.pathname.split(/(?=\/)/g);

        if (
          checkUserIsAuthorisedForRoute(
            configData?.namespace?.configuration?.[0]?.value?.configuration
              ?.menu,
            user,
            pathnames,
          )
        ) {
          setRouteIsAuthorised(true);
        }
      } else {
        navigate("/login");
      }
    }
  }, [
    isLoading,
    isAuthenticated,
    user,
    navigate,
    isLoadingUiConfig,
    configData,
    location,
  ]);

  return (
    <>
      {(isLoading || isLoadingUiConfig) && <Loading width={75} height={75} />}
      {!isLoading && !routeIsAuthorised && !isLoadingUiConfig && (
        <div className="main-body">Access denied</div>
      )}
      {isAuthenticated && routeIsAuthorised && <Component />}
    </>
  );
};
