import dayjs from "dayjs";

export function formatDateTime(
  value: string | number | Date,
  format?: string,
): string {
  if (value == null) {
    return "";
  }

  const dateValue = dayjs(value);
  if (dateValue.isValid()) {
    return dateValue.format(format ?? "DD-MMM-YYYY HH:mm:ss");
  } else {
    return "";
  }
}
