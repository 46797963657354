import { MenuItem } from "../../types/MenuItem";
import { AppSettings } from "../configuration/settings";

export const constructUrlFromMenuItem = (
  menuItem: MenuItem,
  parentMenuItem: MenuItem,
  settings: AppSettings,
) => {
  let url = parentMenuItem
    ? parentMenuItem.route + menuItem.route
    : menuItem.route;

  // Append the embedded path and type to the URL (if application)
  if (
    menuItem.externalPathType?.length > 0 &&
    menuItem.externalPath?.length > 0
  ) {
    const embeddedApplication = settings.embeddedApplications?.filter(
      (embeddedApplication) =>
        embeddedApplication.key === menuItem.externalPathType,
    )?.[0];

    if (embeddedApplication) {
      url = `/embed${url}?app=${
        embeddedApplication?.id
      }&path=${encodeURIComponent(menuItem.externalPath)}`;
    }
  }

  return url;
};
