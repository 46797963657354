import type { ColDef, GridOptions, SideBarDef } from "ag-grid-community";
import { agedDateTimeColumn } from "./grid/columnTypes/agedDateTimeColumn";
import { currencyAggregatedColumn } from "./grid/columnTypes/currencyAggregatedColumn";
import { dateColumn } from "./grid/columnTypes/dateColumn";
import { dateTimeColumn } from "./grid/columnTypes/dateTimeColumn";
import { integerColumn } from "./grid/columnTypes/integerColumn";
import { integerAggregatedColumn } from "./grid/columnTypes/integerAggregatedColumn";
import { priceAggregatedColumn } from "./grid/columnTypes/priceAggregatedColumn";
import { GridRowSelectionColumnHeader } from "./grid/components/GridRowSelectionColumnHeader";

export const pendingStates = ["PENDING", "PROCESSING"] as const;

const columnTypes: GridOptions["columnTypes"] = {
  agedDateTimeColumn,
  currencyAggregatedColumn,
  dateColumn,
  dateTimeColumn,
  integerColumn,
  integerAggregatedColumn,
  priceAggregatedColumn,
};

const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
      },
    },
  ],
};

const autoGroupColumnDef: ColDef = {
  width: 130,
};

const statusBar = {
  statusPanels: [
    {
      statusPanel: "agTotalAndFilteredRowCountComponent",
      align: "left",
    },
    { statusPanel: "agAggregationComponent" },
  ],
};

export const getDefaultGridOptions = ({
  rowStateColumnName,
}: {
  rowStateColumnName: string;
}): GridOptions => ({
  columnTypes,
  autoGroupColumnDef,
  suppressDragLeaveHidesColumns: true,
  suppressAggFuncInHeader: true,
  groupDisplayType: "multipleColumns",
  editType: "fullRow",
  singleClickEdit: false,
  stopEditingWhenCellsLoseFocus: true,
  undoRedoCellEditing: false,
  rowSelection: "multiple",
  enableRangeSelection: false,
  suppressCellFocus: true,
  // Disabled for now, because it interferes with the horizontal alignment of the cell contents
  // enableCellTextSelection: true,
  // ensureDomOrder: true,
  statusBar,
  enableCharts: false,
  sideBar,
  tooltipShowDelay: 0,
  isRowSelectable: (rowNode) => {
    const isRowUpdatePending = pendingStates.includes(
      rowNode.data?.[rowStateColumnName],
    );

    return !rowNode.group && !isRowUpdatePending;
  },

  // @ts-ignore
  tooltipShowDelay: 0,
  tooltipHideDelay: 3000,
});

export type CellValue = {
  fieldName: string;
  value: any;
};

export const rowSelectionColumn: ColDef = {
  checkboxSelection(params) {
    if (!params.node.group) {
      return true;
    }

    return false;
  },
  width: 30,
  resizable: false,
  lockPosition: true,
  suppressMenu: true,
  pinned: "left",
  enableCellChangeFlash: false,
  headerComponent: GridRowSelectionColumnHeader,
};
