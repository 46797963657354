import { Modal } from "react-bootstrap";
import { ModalBase, ModalBaseProps } from "./ModalBase";
import { Button } from "./Button";
import { FormProvider, useForm } from "react-hook-form";
import { GridRowEditorFormBody, isValue } from "./GridRowEditorFormBody";
import { useColumnDefsForSelectedRows } from "../utils/hooks/useColumnDefsForSelectedRows";
import { useAppSelector } from "../redux";
import { Selectors } from "../redux/selectors";
import { useGridApis } from "./GridApiContext";
import { CellValue } from "./Grid.config";
import { ModalSectionHeader } from "./ModalSectionHeader";
import { FormTextareaField } from "./FormTextareaField";

interface GridRowEditorModalProps extends ModalBaseProps {
  updateDataFields: (id: string, cellValue: CellValue[]) => void;
  onRowValueChanged: (
    id: string,
    updatedCells: CellValue[],
    concurrencyStamp: string,
  ) => void;
}

const maxNoteLength = 1024;

export function GridRowEditorModal({
  isOpen,
  closeModal,
  updateDataFields,
  onRowValueChanged,
}: GridRowEditorModalProps) {
  const { gridApi } = useGridApis();
  // TODO...pass in grid type as a prop
  const selectedRows = useAppSelector((state) =>
    Selectors.gridSelectedRows("FailsSummary", state),
  );
  const editorModalMode = useAppSelector((state) =>
    Selectors.editorModalMode("FailsSummary", state),
  );
  const columnDefsForEditingRow = useColumnDefsForSelectedRows();
  const isEditingMode = editorModalMode === "edit";

  const formMethods = useForm({
    defaultValues: null,
  });

  const onSubmit = formMethods.handleSubmit((values) => {
    selectedRows.forEach((row) => {
      const rowNode = gridApi.getRowNode(row.id);
      const updatedCells = [];

      Object.entries(values).forEach(([key, value]) => {
        const initialValue = row[key];
        let isValueChanged = value !== initialValue;

        // handle the case where the initial value is blank and the updated value is setToBlank
        // which means, the value does not change
        if (isValueChanged && !initialValue && isValue.setToBlank(value)) {
          isValueChanged = false;
        }

        if (isValueChanged && !isValue.defaultBlank(value)) {
          updatedCells.push({
            fieldName: key,
            value: isValue.setToBlank(value) ? "" : value,
          });
        }
      });

      const hasChangesSendToServer = updatedCells.length > 0;
      if (hasChangesSendToServer) {
        // update the grid to PENDING status
        updateDataFields(rowNode.id, updatedCells);

        // send the request
        onRowValueChanged(rowNode.id, updatedCells, row.concurrencyStamp);
      }
    });

    closeModal();

    gridApi.deselectAll();
  });

  return (
    <FormProvider {...formMethods}>
      <ModalBase className="base-modal" isOpen={isOpen} closeModal={closeModal}>
        <Modal.Header>
          <Modal.Title className="fs-4 fw-bold">
            {isEditingMode ? "Edit Record" : "Note"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isEditingMode && (
            <>
              <ModalSectionHeader title="Record Details">
                Update one or more editable fields of the selected record(s).
              </ModalSectionHeader>
              <GridRowEditorFormBody
                columnDefsForEditingRow={columnDefsForEditingRow}
              />
            </>
          )}
          <ModalSectionHeader title="Notes">
            {isEditingMode
              ? "Add a new note explaining why this update is being made."
              : "Add a new note to the selected record(s)."}
          </ModalSectionHeader>
          <FormTextareaField
            id="latestNote"
            defaultValue=""
            label="Note"
            validate={(value) => {
              if (value && value.length > maxNoteLength) {
                return `The note entered exceeds the current character limit (${maxNoteLength} characters), please enter a shorter note.`;
              } else {
                return true;
              }
            }}
          />
        </Modal.Body>

        <Modal.Footer className="center-children">
          <Button
            text={isEditingMode ? "Save" : "Add"}
            type="submit"
            onClick={onSubmit}
          />
          <Button
            text="Cancel"
            onClick={closeModal}
            variant="outline-secondary"
          />
        </Modal.Footer>
      </ModalBase>
    </FormProvider>
  );
}
