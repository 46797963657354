const NotFoundPage = (): JSX.Element => {
  return (
    <div className="main-body">
      <h1>404 Not Found</h1>
      <p>We're sorry, but the page you requested cannot be found.</p>
    </div>
  );
};

export default NotFoundPage;
