import { useEffect, useState, RefObject } from "react";
import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs, { Dayjs } from "dayjs";
import {
  CASH_MARGIN_SUMMARY_QUERY,
  CASH_MARGIN_SUMMARY_RECORD_SET_QUERY,
} from "./graphql";
import { extractApolloErrorMessages } from "../../utils/graphql/extractApolloErrorMessages";
import { usePrevious } from "../../utils/hooks/usePrevious";
import { config } from "../../utils/configuration/settings";
import { Grid } from "../../components/Grid";
import { NewDataAvailableAlert } from "../../components/NewDataAvailableAlert";
import { ErrorCollection } from "../../components/ErrorCollection";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { Loading } from "../../components/Loading";
import { GET_CONFIGURATION_QUERY } from "../../graphql";

const FALLBACK_DATA_FETCH_POLLING_INTERVAL = 60000; // 60 secs

interface SummaryProps {
  gridRef?: RefObject<any>;
}

export const Summary = ({ gridRef }: SummaryProps): JSX.Element => {
  // Get settings...TODO, the settings should be passed down as React context
  const settings = config.get();

  // User context
  const { isAuthenticated, user } = useAuth0();

  // State
  const [recordSetWhenLastUpdated, setRecordSetWhenLastUpdated] =
    useState<Dayjs>(null);
  const [isNewDataAvailable, setIsNewDataAvailable] = useState(false);
  const [
    disableNotificationOfNewDataAvailable,
    setDisableNotificationOfNewDataAvailable,
  ] = useState(false);

  // Variable and custom hook that stores the previous state of recordSetWhenLastUpdated
  const prevRecordSetWhenLastUpdated: Dayjs = usePrevious(
    recordSetWhenLastUpdated,
  );

  // GraphQL queries

  // Query module access for recordSetId
  const { data: moduleAccessData, loading: isLoadingModuleAccess } = useQuery(
    GET_CONFIGURATION_QUERY,
    {
      variables: {
        namespace: user
          ? settings.namespace +
            "/" +
            user["https://c3posttrade.com/subscriptionId"]
          : null,
        configKey: "moduleAccess",
      },
      skip:
        !isAuthenticated ||
        (user && !user["https://c3posttrade.com/subscriptionId"]),
    },
  );

  // Query record set data
  const { data, loading, error, refetch } = useQuery(
    CASH_MARGIN_SUMMARY_QUERY,
    {
      variables: {
        recordSetId:
          moduleAccessData?.namespace?.configuration?.[0]?.value?.filter(
            (_) => _.module === "cash-margin",
          )[0]?.recordSetId,
      },
      skip: !moduleAccessData,
    },
  );

  // Query for record set updates
  const {
    data: recordSetPollingData,
    error: recordSetPollingError,
    startPolling,
    stopPolling,
  } = useQuery(CASH_MARGIN_SUMMARY_RECORD_SET_QUERY, {
    // Skip running query until CASH_MARGIN_SUMMARY_QUERY has returned data
    skip: !data || !moduleAccessData,
    // Poll at specified interval
    pollInterval:
      settings.defaultDataFetchPollingInterval ??
      FALLBACK_DATA_FETCH_POLLING_INTERVAL,
    // Always fetch from the server and don't save the result in the cache (the actual data fetch will do that)
    fetchPolicy: "no-cache",
    variables: {
      recordSetId:
        moduleAccessData?.namespace?.configuration?.[0]?.value?.filter(
          (_) => _.module === "cash-margin",
        )[0]?.recordSetId,
    },
  });

  // Whenever data changes, set recordSetWhenLastUpdated if the date is newer
  useEffect(() => {
    console.debug(
      `[${dayjs().format("HH:mm:ss")}] CashMarginSummary side effect [data]`,
    );

    if (data?.cashMarginSummary?.whenLastUpdated) {
      const whenLastUpdated = dayjs(data.cashMarginSummary.whenLastUpdated);
      if (whenLastUpdated > recordSetWhenLastUpdated) {
        console.debug(
          `[${dayjs().format(
            "HH:mm:ss",
          )}] recordSetWhenLastUpdated changed, whenLastUpdated = ${
            data.cashMarginSummary.whenLastUpdated
          }`,
        );
        setDisableNotificationOfNewDataAvailable(true);
        setRecordSetWhenLastUpdated(whenLastUpdated);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Whenever recordSetPollingData changes, set recordSetWhenLastUpdated
  useEffect(() => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] CashMarginSummary side effect [recordSetPollingData]: recordSetPollingData = ${JSON.stringify(
        recordSetPollingData,
      )}`,
    );

    if (recordSetPollingData?.cashMarginSummary?.whenLastUpdated) {
      const whenLastUpdated = dayjs(
        recordSetPollingData.cashMarginSummary.whenLastUpdated,
      );
      if (whenLastUpdated > recordSetWhenLastUpdated) {
        console.debug(
          `[${dayjs().format(
            "HH:mm:ss",
          )}] recordSetWhenLastUpdated changed, whenLastUpdated = ${
            recordSetPollingData.cashMarginSummary.whenLastUpdated
          }`,
        );
        setRecordSetWhenLastUpdated(whenLastUpdated);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordSetPollingData]);

  // Whenever recordSetWhenLastUpdated changes, then set isNewDataAvailable
  useEffect(() => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] CashMarginSummary side effect [recordSetWhenLastUpdated]: recordSetWhenLastUpdated = ${JSON.stringify(
        recordSetWhenLastUpdated,
      )}, prevRecordSetWhenLastUpdated = ${JSON.stringify(
        prevRecordSetWhenLastUpdated,
      )}, disableNotificationOfNewDataAvailable = ${disableNotificationOfNewDataAvailable}`,
    );

    if (
      recordSetWhenLastUpdated &&
      prevRecordSetWhenLastUpdated &&
      !disableNotificationOfNewDataAvailable
    ) {
      setIsNewDataAvailable(
        recordSetWhenLastUpdated > prevRecordSetWhenLastUpdated,
      );

      console.debug(
        `[${dayjs().format("HH:mm:ss")}] Set isNewDataAvailable = ${
          recordSetWhenLastUpdated > prevRecordSetWhenLastUpdated
        }`,
      );
    }

    setDisableNotificationOfNewDataAvailable(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordSetWhenLastUpdated]);

  // Whenever isNewDataAvailable is true then stop polling for updated data (while the popup is open)
  useEffect(() => {
    console.debug(
      `CashMarginSummary side effect [isNewDataAvailable]: isNewDataAvailable = ${isNewDataAvailable}`,
    );

    if (isNewDataAvailable) {
      stopPolling();
      console.debug(
        `[${dayjs().format("HH:mm:ss")}] Stop polling for new data`,
      );
    } else {
      startPolling(
        settings.defaultDataFetchPollingInterval ??
          FALLBACK_DATA_FETCH_POLLING_INTERVAL,
      );
      console.debug(
        `[${dayjs().format("HH:mm:ss")}] Start polling for new data`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewDataAvailable]);

  return (
    <ErrorBoundary>
      <div className="main-body">
        <div className="main-grid">
          {(loading || isLoadingModuleAccess) && (
            <Loading width={75} height={75} />
          )}
          {error && (
            <ErrorCollection
              header="There was a problem fetching the data"
              errorMessages={extractApolloErrorMessages(error)}
            />
          )}
          {recordSetPollingError && (
            <ErrorCollection
              header="There was a problem polling for updated data"
              errorMessages={extractApolloErrorMessages(recordSetPollingError)}
            />
          )}
          {data && (
            <Grid
              gridRef={gridRef}
              type="CashMarginSummary"
              heading="Margin Summary"
              subHeading="Manage Excess / Deficit"
              data={data.cashMarginSummary.records}
              onRefreshClicked={refetch}
              userId={user?.sub}
            ></Grid>
          )}
          {isNewDataAvailable && (
            <NewDataAvailableAlert
              onRefresh={() => {
                setIsNewDataAvailable(false);
                refetch();
              }}
              onClose={() => setIsNewDataAvailable(false)}
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};
