// update an object with its partial counter part
export function updateObj(prevObj: any, nextObj: any) {
  for (const key in prevObj) {
    const isObjectProperty =
      typeof prevObj[key] === "object" && !Array.isArray(prevObj[key]);
    const nextObjHasTheSameKey = key in nextObj;

    // if the value is object type, we update it recursively
    if (isObjectProperty) {
      return updateObj(prevObj[key], nextObj[key]);
    }

    if (!isObjectProperty && nextObjHasTheSameKey) {
      prevObj[key] = nextObj[key];
    }
  }
}
