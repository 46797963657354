import dayjs from "dayjs";

export const dateTimeSortComparator = (
  valueA: string,
  valueB: string,
): number => {
  const dateValueA = dayjs(valueA);
  const dateValueB = dayjs(valueB);

  // Check for nulls
  if (!dateValueA.isValid() && !dateValueB.isValid()) {
    return 0;
  }
  if (!dateValueA.isValid()) {
    return -1;
  }
  if (!dateValueB.isValid()) {
    return 1;
  }

  // Return difference (in number of milliseconds)
  return dateValueA.diff(dateValueB);
};
