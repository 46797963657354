import { currencyFormatter } from "../valueFormatters/currencyFormatter";
import { numberCellStyle } from "../cellStyles/numberCellStyle";

export const currencyAggregatedColumn = {
  type: "rightAligned",
  aggFunc: "sum",
  enableValue: true,
  valueFormatter: currencyFormatter,
  cellStyle: numberCellStyle,
  filter: "agNumberColumnFilter",
  filterParams: {
    buttons: ["reset"],
    inRangeInclusive: true,
  },
};
