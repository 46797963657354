import { AppState } from "./index";
import { initialGridStateProperties } from "./slices/gridSlice";

export const Selectors = {
  gridSelectedRows(type: string, state: AppState) {
    if (state.grid[type]) {
      return state.grid[type].selectedRows;
    } else {
      return initialGridStateProperties.selectedRows;
    }
  },
  editorModalMode(type: string, state: AppState) {
    if (state.grid[type]) {
      return state.grid[type].editorModalMode;
    } else {
      return initialGridStateProperties.editorModalMode;
    }
  },
  isGridBulkEditing(type: string, state: AppState) {
    if (state.grid[type]) {
      return state.grid[type].selectedRows.length > 1;
    } else {
      return false;
    }
  },
  isGridEditing(type: string, state: AppState) {
    if (state.grid[type]) {
      return state.grid[type].selectedRows.length > 0;
    } else {
      return false;
    }
  },
  isGridReady(type: string, state: AppState) {
    if (state.grid[type]) {
      return state.grid[type].isGridReady;
    } else {
      return initialGridStateProperties.isGridReady;
    }
  },
  user(state: AppState) {
    return state.auth.user;
  },
  isUserEmailAvailable(state: AppState) {
    return state.auth.user && state.auth.user.email?.length > 0;
  },
  isUserAuthenticatedWithEmbeddedApplication(state: AppState, id: number) {
    return state.auth.embeddedApplicationAuthenticationState?.find(
      (x) => x.id === id,
    )?.isAuthenticated;
  },
  isUserImpersonating(state: AppState) {
    return state.auth.isUserImpersonating;
  },
  isImpersonationSwitching(state: AppState) {
    return state.auth.isImpersonationSwitching;
  },
  embeddedApplicationAuthenticationError(state: AppState, id: number) {
    return state.auth.embeddedApplicationAuthenticationState?.find(
      (x) => x.id === id,
    )?.authenticationError;
  },
};
