import "./PageHeading.scss";

interface PageHeadingProps {
  heading: string;
  subHeading?: string;
}

export const PageHeading = (props: PageHeadingProps): JSX.Element => {
  return (
    <div className="m-1 fs-5">
      <span className="page-heading fw-bold">{props.heading}</span>
      {props.subHeading && (
        <span className="page-sub-heading">
          &nbsp;|&nbsp;{props.subHeading}
        </span>
      )}
    </div>
  );
};
