import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useAppSelector } from "../redux";
import { Selectors } from "../redux/selectors";
import { NoteHistoryTab } from "./NoteHistoryTab";
import "./GridLowerTabs.scss";

export function GridLowerTabs({
  gridContainerStyle,
}: {
  gridContainerStyle?: React.CSSProperties;
}) {
  // TODO...pass down parent grid type
  const isGridReady = useAppSelector((state) =>
    Selectors.isGridReady("FailsSummary", state),
  );
  const [tabKey, setTabKey] = useState("notes");
  const [isTabVisible, setIsTabVisible] = useState(true);

  if (!isGridReady) return null;

  return (
    <div
      className={`grid-lower-tabs-base ${
        isTabVisible ? "grid-lower-tabs-shown" : "grid-lower-tabs-hidden"
      }`}
    >
      <Tabs
        defaultActiveKey="notes"
        onSelect={(key) => {
          const isSelectCurrentTab = tabKey === key;

          if (isSelectCurrentTab) {
            setIsTabVisible((v) => !v);
          } else {
            setTabKey(key);
          }
        }}
        variant="pills"
      >
        <Tab eventKey="notes" title="Notes" className="grid-lower-tabs-tab">
          {isTabVisible && (
            <NoteHistoryTab gridContainerStyle={gridContainerStyle} />
          )}
        </Tab>
      </Tabs>
    </div>
  );
}
