import { useState } from "react";
import Toast from "react-bootstrap/Toast";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Error.scss";

interface ErrorProps {
  header: string;
  errorMessage: string | JSX.Element;
  onClose?: () => void;
}

export const Error = (props: ErrorProps): JSX.Element => {
  const [showError, setShowError] = useState(true);

  return (
    <div className="d-flex justify-content-center position-relative errorContainer">
      <Toast
        show={showError}
        onClose={() => {
          setShowError(false);
          if (props.onClose) {
            props.onClose();
          }
        }}
        className="position-absolute"
        style={{ top: 180 }}
      >
        <Toast.Header>
          <strong className="me-auto">{props.header}</strong>
        </Toast.Header>
        <Toast.Body>
          <Container>
            <Row>
              <Col>{props.errorMessage}</Col>
            </Row>
            <Row className="mt-3 justify-content-center">
              <Col className="col-3 text-center">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowError(false);
                    if (props.onClose) {
                      props.onClose();
                    }
                  }}
                >
                  Close
                </a>
              </Col>
            </Row>
          </Container>
        </Toast.Body>
      </Toast>
    </div>
  );
};
