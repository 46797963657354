export const appendQueryStringParameterToRelativePath = (
  path: string,
  parameterName: string,
  parameterValue: string,
) => {
  const parsedUrl = new URL(path, "http://www.c3posttrade.com");

  const searchParams = parsedUrl.searchParams;
  searchParams.append(parameterName, parameterValue);

  return parsedUrl.pathname + parsedUrl.search;
};
