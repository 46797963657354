import { gql } from "@apollo/client";

const failsRecordFields = [
  "id",
  "localEntity",
  "counterparty",
  "counterpartyEntity",
  "counterpartyStatus",
  "tradeReference",
  "failAge",
  "failAgeInBusinessDays",
  "workStatus",
  "failClassification",
  "counterpartyFailType",
  "securityDescription",
  "executingBroker",
  "beneficiaryBic",
  "accountNumber",
  "accountName",
  "accountType",
  "buySell",
  "tradeDate",
  "settlementStatus",
  "settlementDate",
  "actualSettlementDate",
  "primarySecurityId",
  "isin",
  "sedol",
  "cusip",
  "bloomberg",
  "ric",
  "currency",
  "quantity",
  "tradePrice",
  "netConsideration",
  "counterpartyComments",
  "csdrEligibility",
  "csd",
  "securityType",
  "mic",
  "cfiCode",
  "matchingDateTime",
  "settlementCutOffDateTime",
  "penaltyType",
  "securityPenaltyAmount",
  "csdSecurityPenaltyAmount",
  "cashPenaltyAmount",
  "csdCashPenaltyAmount",
  "cashPenaltyRate",
  "securityPenaltyRate",
  "liquidityIndicator",
  "smeIndicator",
  "csdrInstrumentType",
  "penaltyAmountCurrency",
  "exchangeRate",
  "penaltyBaseCurrency",
  "baseSecurityPenaltyAmount",
  "baseCsdSecurityPenaltyAmount",
  "baseCashPenaltyAmount",
  "baseCsdCashPenaltyAmount",
  "penaltyDirection",
  "daysToBuyIn",
  "buyInResponsibility",
  "penaltyDate",
  "psetBic",
  "psetName",
  "whenRecordReceived",
  "whenRecordUpdated",
  "concurrencyStamp",
  "recordUpdateState",
];

export const FAILS_SUMMARY_QUERY = gql`
  query GetFailsSummary($recordSetId: ID!) {
    failsSummary(recordSetId: $recordSetId) {
      id
      whenLastUpdated
      records {
        ${failsRecordFields.join("\r\n")}

        latestNote {
          id
          author {
            id
          }
          noteText
          whenNoteCreated
        }
      }
    }
  }
`;

export const FAILS_DETAIL_QUERY = gql`
  query GetFailsDetail($recordSetId: ID!, $id: ID!) {
    failsDetail(recordSetId: $recordSetId, id: $id) {
      ${failsRecordFields.join("\r\n")}

      latestNote {
        id
        author {
          id
        }
        noteText
        whenNoteCreated
      }
    }
  }
`;

export const FAILS_SUMMARY_RECORD_SET_QUERY = gql`
  query GetFailsSummaryRecordSet($recordSetId: ID!) {
    failsSummary(recordSetId: $recordSetId) {
      id
      whenLastUpdated
    }
  }
`;

// TODO...this is really a generic record update mutation (rather than fails specific) so where should this live?
export const UPDATE_RECORD_MUTATION = gql`
  mutation UpdateRecord(
    $recordId: ID!
    $concurrencyStamp: String!
    $fields: [FieldInput!]
    $noteText: String
  ) {
    updateRecord(
      recordId: $recordId
      concurrencyStamp: $concurrencyStamp
      fields: $fields
      noteText: $noteText
    ) {
      id
      recordId
      fields {
        name
        value
      }
      result
      messages
      whenRecordUpdateRequestExpires
      noteText
    }
  }
`;

// TODO...this is really a generic record update query (rather than fails specific) so where should this live?
export const RECORD_UPDATE_RESPONSES_QUERY = gql`
  query GetRecordUpdateResponses($whenUserLastPolled: String) {
    recordUpdateResponses(whenUserLastPolled: $whenUserLastPolled) {
      timestamp
      results {
        id
        recordId
        fields {
          name
          value
        }
        result
        messages
        whenRecordUpdateRequestExpires
        noteText
      }
    }
  }
`;
