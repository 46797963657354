import "./EnvironmentLabel.scss";

interface EnvironmentLabelProps {
  environment: string;
  application: string;
}

const environmentLabel = (environment: string, application: string) => {
  const applicationDisplayName = application?.toUpperCase();
  const environmentDisplayName = environment?.toUpperCase();

  return `${environmentDisplayName === "PROD" ? "" : environmentDisplayName} ${
    applicationDisplayName === "DEFAULT" ? "" : applicationDisplayName
  }`.trim();
};

export const EnvironmentLabel = (props: EnvironmentLabelProps): JSX.Element => {
  const label = environmentLabel(props.environment, props.application);

  if (label?.length > 0) {
    document.title = `C3 Post Trade - ${label}`;
  } else {
    document.title = "C3 Post Trade";
  }

  return (
    <>
      {label?.length > 0 && (
        <div className="environment-container">
          <div className="environment-container-wrapper">
            <div className="environment-container-ribbon fw-bold">{label}</div>
          </div>
        </div>
      )}
    </>
  );
};
