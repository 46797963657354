import { ApolloError } from "@apollo/client";

export function extractApolloErrorMessages(apolloError: ApolloError): string[] {
  const errorMessages: string[] = [];

  if (apolloError) {
    const { graphQLErrors, networkError } = apolloError;

    if (graphQLErrors) {
      // Returns unique error messages using the ES6 Set object
      const errorMessageSet = new Set(
        graphQLErrors.map(({ message }) => {
          return message;
        }),
      );
      errorMessageSet.forEach((message) => errorMessages.push(message));
    }

    if (networkError) {
      errorMessages.push(networkError.message);
    }
  }

  return errorMessages;
}
