import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { config } from "../configuration/settings";
import { AUTH_AUDIENCE, USER_IMPERSONATION_COOKIE_KEY } from "../../constants";
import { Error } from "../../components/Error";
import { useAppSelector } from "../../redux";
import Cookies from "js-cookie";

const getParameter = (parameterName: string): string => {
  const parameters = new URLSearchParams(window.location.search);
  return parameters.get(parameterName);
};

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const settings = config.get();

  const userState = useAppSelector((state) => state.auth.user);

  const [invitationParameter] = useState<string>(getParameter("invitation"));
  const [organisationParameter] = useState<string>(
    getParameter("organization"),
  );
  const [errorMessage, setErrorMessage] = useState<string>(
    getParameter("error_description"),
  );

  const navigate = useNavigate();

  const impersonationRequest =
    Cookies.get(USER_IMPERSONATION_COOKIE_KEY) ?? undefined;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const onErrorClose = () => {
    // This will end up redirecting to the login page
    navigate(window.location.pathname);
    setErrorMessage(null);
  };

  return (
    <React.Fragment>
      {errorMessage && (
        <Error
          header="There was a problem authenticating"
          errorMessage={errorMessage}
          onClose={onErrorClose}
        />
      )}
      {!errorMessage && (
        <Auth0Provider
          domain={settings.authDomain}
          clientId={settings.authClientId}
          audience={AUTH_AUDIENCE}
          impersonationRequest={impersonationRequest}
          organization={
            organisationParameter ?? userState.organisationId ?? undefined
          }
          redirectUri={window.location.origin}
          useRefreshTokens={true}
          invitation={invitationParameter ?? undefined}
          onRedirectCallback={onRedirectCallback}
        >
          {children}
        </Auth0Provider>
      )}
    </React.Fragment>
  );
};
