import { gql } from "@apollo/client";

export const FIND_ORGANISATIONS_BY_EMAIL_QUERY = gql`
  query OrganisationsWithSpecifiedEmailDomain($email: String!) {
    organisationsWithSpecifiedEmailDomain(email: $email) {
      organisations {
        id
        name
        externalDirectory {
          organisationId
        }
      }
    }
  }
`;
