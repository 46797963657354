import { useEffect, useRef } from "react";

export function usePollingEffect(
  asyncCallback,
  dependencies = [],
  {
    interval = 1000, // 1 second,
    pollingSwitch = true,
  } = {},
) {
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    let _stopped = false;

    (async function pollingCallback() {
      try {
        if (pollingSwitch) {
          await asyncCallback();
        }
      } finally {
        // Set timeout after it finished, unless stopped or pollingSwitch is off
        timeoutIdRef.current =
          !_stopped && pollingSwitch && setTimeout(pollingCallback, interval);
      }
    })();

    // Clean up if dependencies change
    return () => {
      _stopped = true; // prevent racing conditions
      clearTimeout(timeoutIdRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, interval, asyncCallback, pollingSwitch]);
}
