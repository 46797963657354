export function formatNumber(
  value: number,
  numberOfDecimalPlaces?: number,
): string {
  if (value == null || isNaN(value)) {
    return "";
  }

  return new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: numberOfDecimalPlaces ?? 2,
    maximumFractionDigits: numberOfDecimalPlaces ?? 2,
  }).format(value);
}
