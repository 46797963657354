import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0, User } from "@auth0/auth0-react";
import { gql, useQuery } from "@apollo/client";
import { WithAuthentication } from "./utils/auth/WithAuthentication";
import { checkUserHasPermission } from "./utils/auth/checkUserHasPermission";
import { config } from "./utils/configuration/settings";
import { AppHeader } from "./components/AppHeader";
import { AppRoutes } from "./AppRoutes";
import { GET_CONFIGURATION_QUERY, GET_TRUSTED_RELATIONSHIPS } from "./graphql";
import { WithImpersonationStatePolling } from "./utils/auth/WithImpersonationStatePolling";
import { useAppSelector } from "./redux";
import { Selectors } from "./redux/selectors";
import { MenuItem } from "./types/MenuItem";
import { NavigationContext } from "./context/NavigationContext";
import "./App.scss";

export const GET_USER_QUERY = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      user {
        organisation {
          name
        }
        firstName
        lastName
      }
    }
  }
`;

const transformMenu = (menu: MenuItem[], user: User): MenuItem[] => {
  return menu?.map((menuItem) => {
    let subMenuItems: MenuItem[];
    if (menuItem.items?.length > 0) {
      subMenuItems = transformMenu(menuItem.items, user);
    }

    return {
      ...menuItem,
      disabled: !checkUserHasPermission(user, menuItem.requiredPermission),
      items: subMenuItems,
    };
  });
};

const findActiveMenuItem = (routes: string[], menu: MenuItem[]) => {
  let activeMenuItem: MenuItem;

  // Find the top level menu item
  if (routes?.length > 0 && menu) {
    activeMenuItem = menu?.find((item) => item.route === "/" + routes[0]);
  }

  return activeMenuItem;
};

// TODO...make this dynamic to support more than 2 levels of menu
const transformCrumbs = (routes: string[], menu: MenuItem[]) => {
  // Find the top level menu item
  if (routes?.length > 0 && menu) {
    const menuItem = menu?.find((item) => item.route === "/" + routes[0]);
    routes[0] = menuItem?.text;

    // Find the second level menu item
    if (routes.length > 1) {
      const subMenuItem = menuItem?.items?.find(
        (item) => item.route === "/" + routes[1],
      );
      routes[1] = subMenuItem?.text;
    }
  }

  return routes;
};

export const App = () => {
  // Get settings...TODO, the settings should be passed down as React context
  const settings = config.get();

  const [crumbs, setCrumbs] = useState<string[]>([]);
  const [activeMenuItem, setActiveMenuItem] = useState<MenuItem>();
  const [hasPermissionToImpersonate, setHasPermissionToImpersonate] =
    useState(false);
  const isUserImpersonating = useAppSelector((state) =>
    Selectors.isUserImpersonating(state),
  );

  // Returns a new location whenever the URL changes (including client side routing)
  const location = useLocation();

  const { user, isAuthenticated } = useAuth0();

  const { data: originalUserData } = useQuery(GET_USER_QUERY, {
    variables: {
      id: user ? user["https://c3posttrade.com/iamUserId"] : null,
    },
    skip:
      !isAuthenticated || (user && !user["https://c3posttrade.com/iamUserId"]),
  });

  const { data: impersonatedUserData } = useQuery(GET_USER_QUERY, {
    variables: {
      id: user ? user["https://c3posttrade.com/activeIamUserId"] : null,
    },
    skip:
      !isAuthenticated ||
      !isUserImpersonating ||
      (user && !user["https://c3posttrade.com/activeIamUserId"]),
  });

  const { data: configData } = useQuery(GET_CONFIGURATION_QUERY, {
    variables: {
      namespace: settings.namespace,
      configKey: "ui-navigation",
    },
    skip: !isAuthenticated,
  });

  const { data: trustedRelationshipsData } = useQuery(
    GET_TRUSTED_RELATIONSHIPS,
    {
      variables: {
        // we use the iamOrganisationId here instead of the activeIamOrganisationId
        // because we do not support nested impersonation at this point in time so we use iamOrganisationId as the origin.
        // Subject to change in the future
        organisationId: user
          ? user["https://c3posttrade.com/iamOrganisationId"]
          : null,
      },
      skip:
        !isAuthenticated ||
        (user && !user["https://c3posttrade.com/iamOrganisationId"]) ||
        !hasPermissionToImpersonate,
    },
  );

  const navigationContextValue = useMemo(
    () => ({
      menu: transformMenu(
        configData?.namespace?.configuration?.[0]?.value?.configuration?.menu,
        user,
      ),
      activeMenuItem,
      crumbs,
    }),
    [crumbs, activeMenuItem, configData, user],
  );

  useEffect(() => {
    setHasPermissionToImpersonate(
      checkUserHasPermission(
        user,
        settings.namespace +
          "/operationalSupport/invokeTrustRelationship/action",
      ),
    );
  }, [user, settings.namespace]);

  // Hook for triggering page view events whenever the client side route changes
  useEffect(() => {
    // Send a new pageView event to Raygun
    rg4js("trackEvent", { type: "pageView", path: location.pathname });

    // Persist current route in local storage (so can redirect here when logging in)
    if (
      !isUserImpersonating &&
      location.pathname &&
      location.pathname !== "/" &&
      location.pathname !== "/login"
    ) {
      // Specific use case for not caching the resync query string parameter relates to syncing embedded app routes
      const currentRoute =
        location.pathname + location.search.replace("&resync=true", "");
      localStorage.setItem("currentRoute", currentRoute);
    }
  }, [isUserImpersonating, location]);

  useEffect(() => {
    // Split the routes (filtering out any null values and excluding the /embed route")
    const routes = location.pathname
      .split("/")
      .filter((value) => value && value !== "embed");

    // Set the active menu item
    setActiveMenuItem(
      findActiveMenuItem(
        routes,
        transformMenu(
          configData?.namespace?.configuration?.[0]?.value?.configuration?.menu,
          user,
        ),
      ),
    );

    // Construct the breadcrumb from the navigation config using the current route
    setCrumbs(
      transformCrumbs(
        routes,
        configData?.namespace?.configuration?.[0]?.value?.configuration?.menu,
      ),
    );
  }, [location, configData, user]);

  useEffect(() => {
    if (
      configData?.namespace?.configuration &&
      configData.namespace.configuration.length > 1
    ) {
      console.log(
        `Multiple grid configurations returned for application: ${settings.applicationName} and grid type: ui-navigation, defaulting to the first one`,
      );
    }
  }, [configData, settings.applicationName]);

  return (
    <WithAuthentication>
      <NavigationContext.Provider value={navigationContextValue}>
        {isAuthenticated && configData && (
          <WithImpersonationStatePolling>
            <AppHeader
              firstName={originalUserData?.user?.user?.firstName}
              lastName={originalUserData?.user?.user?.lastName}
              organisationName={
                originalUserData?.user?.user?.organisation?.name
              }
              impersonatedOrganisationName={
                impersonatedUserData?.user?.user?.organisation?.name
              }
              trustedRelationships={trustedRelationshipsData?.organisation?.organisation?.trustedRelationships?.map(
                (trustedRelationship) => {
                  return {
                    opposingOrganisationName:
                      trustedRelationship.opposingOrganisation.name,
                    id: trustedRelationship.id,
                    opposingOrganisationId:
                      trustedRelationship.opposingOrganisation.id,
                  };
                },
              )}
            />
          </WithImpersonationStatePolling>
        )}
        <AppRoutes settings={settings} />
      </NavigationContext.Provider>
    </WithAuthentication>
  );
};
