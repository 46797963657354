export const typePolicies = {
  User: {
    fields: {
      displayName: {
        read(_, { readField, toReference, args }) {
          const firstName = readField("firstName");
          const lastName = readField("lastName");
          const organisationRefString = readField("organisation") as any;
          if (organisationRefString) {
            const organisationRef = toReference(organisationRefString);
            const organistionId = readField("id", organisationRef);
            const organistionName = readField("name", organisationRef);

            if (organistionId !== args.activeIamOrganisationId) {
              return `${firstName} ${lastName} (${organistionName})`;
            } else {
              return `${firstName} ${lastName}`;
            }
          }
          return undefined;
        },
      },
    },
  },
};
