import dayjs from "dayjs";

export const dateTimeFilterComparator = (
  filterLocalDateAtMidnight: Date,
  cellValue: string,
): -1 | 0 | 1 => {
  const filterDate = dayjs(filterLocalDateAtMidnight).startOf("day"); // This is a bit redundant as ag-grid passes in the filter date at midnight, but just in case
  const cellDate = dayjs(cellValue).startOf("day");

  // Now that both parameters are Dayjs objects, we can compare
  if (cellDate < filterDate) {
    return -1;
  } else if (cellDate > filterDate) {
    return 1;
  }
  return 0;
};
