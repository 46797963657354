import { Modal } from "react-bootstrap";
import { ModalBase, ModalBaseProps } from "./ModalBase";
import { Button } from "./Button";
import { FormProvider, useForm } from "react-hook-form";
import { FormSelectorField } from "./FormSelectorField";
import { ModalSectionHeader } from "./ModalSectionHeader";
import Cookies from "js-cookie";
import { USER_IMPERSONATION_COOKIE_KEY } from "../constants";
import { useAppDispatch } from "../redux";
import { authSlice } from "../redux/slices/authSlice";
import { config } from "../utils/configuration/settings";

export interface TrustedRelationshipItem {
  id: string;
  opposingOrganisationName: string;
  opposingOrganisationId: string;
}

interface LoginAsClientModalProps extends ModalBaseProps {
  trustedRelationships: TrustedRelationshipItem[];
}

export function LoginAsClientModal(props: LoginAsClientModalProps) {
  const formMethods = useForm({
    defaultValues: null,
  });

  const dispatch = useAppDispatch();
  const settings = config.get();

  dispatch(authSlice.actions.setIsImpersonationSwitching(true));

  const clientOptions: Array<{ value: any; label: string }> =
    props.trustedRelationships.map((trustedRelationship) => {
      return {
        label: trustedRelationship.opposingOrganisationName,
        value: trustedRelationship.id,
      };
    });

  const dropdownOptions = [
    // prepopulate an empty entry so there is no "default" client
    {
      label: "",
      value: "empty",
    },
    ...clientOptions,
  ];

  const onSubmit = formMethods.handleSubmit((values) => {
    Object.entries(values).forEach(([, value]) => {
      const matchingTrustedRelationship = props.trustedRelationships?.find(
        (trustedRelationship) => trustedRelationship.id === value,
      );

      // Set cookie expiry according to configuration
      const date = new Date();
      date.setSeconds(
        date.getSeconds() + settings.impersonationExpiryInSeconds,
      );

      Cookies.set(
        USER_IMPERSONATION_COOKIE_KEY,
        JSON.stringify({
          trustRelationshipId: matchingTrustedRelationship.id,
          owningOrganisationId:
            matchingTrustedRelationship.opposingOrganisationId,
          applicationName: settings.applicationName,
          applicationId: settings.applicationId,
        }),
        {
          path: "/",
          httpOnly: false,
          expires: date,
        },
      );
    });

    window.location.assign("/");
  });

  const onCloseOrCancel = () => {
    dispatch(authSlice.actions.setIsImpersonationSwitching(false));

    props.closeModal();
  };

  return (
    <FormProvider {...formMethods}>
      <ModalBase
        className="base-modal"
        isOpen={props.isOpen}
        closeModal={onCloseOrCancel}
      >
        <Modal.Header>
          <Modal.Title className="fs-4 fw-bold">
            Log in to Client Area
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ModalSectionHeader title="">
            Please select a client to log in as
          </ModalSectionHeader>
          <FormSelectorField
            key="key"
            id="id"
            label="Client"
            defaultValue=""
            options={dropdownOptions}
            validate={(value) => {
              if (value !== "empty") {
                return true;
              } else {
                return `Please select a valid client.`;
              }
            }}
          />
        </Modal.Body>

        <Modal.Footer className="center-children">
          <Button text="Log in" type="submit" onClick={onSubmit} />
          <Button
            text="Cancel"
            onClick={onCloseOrCancel}
            variant="outline-secondary"
          />
        </Modal.Footer>
      </ModalBase>
    </FormProvider>
  );
}
