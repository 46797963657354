import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLazyQuery } from "@apollo/client";
import { authSlice } from "../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../redux";
import { Selectors } from "../redux/selectors";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { SUPPORT_EMAIL } from "../constants";
import { FIND_ORGANISATIONS_BY_EMAIL_QUERY } from "./graphql";
import { extractApolloErrorMessages } from "../utils/graphql/extractApolloErrorMessages";
import { ErrorCollection } from "../components/ErrorCollection";
import { Error } from "../components/Error";
import logo from "../assets/images/logo_full.png";
import { useSearchParams } from "react-router-dom";

import "./Login.scss";

enum FormMode {
  ExistingUser = "EXISTING_USER",
  NewUser = "NEW_USER",
}

export const Login = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { loginWithRedirect } = useAuth0();

  const dispatch = useAppDispatch();

  // Query IAM for the Auth0 organisation(s) corresponding to a given email address
  const [organisationsWithSpecifiedEmailDomain, { called, error, data }] =
    useLazyQuery(FIND_ORGANISATIONS_BY_EMAIL_QUERY, {
      context: {
        isPublicQuery: true,
      },
      onError: (error) => setShowApiError(true),
    });

  // Existing state
  const isUserEmailAvailable = useAppSelector(Selectors.isUserEmailAvailable);
  const existingUser = useAppSelector(Selectors.user);

  const [formMode] = useState<FormMode>(() =>
    isUserEmailAvailable ? FormMode.ExistingUser : FormMode.NewUser,
  );
  const [emailAddress, setEmailAddress] = useState<string>(existingUser.email);
  const [useExistingEmail, setUseExistingEmail] =
    useState<boolean>(isUserEmailAvailable);
  const [showUnknownEmailError, setShowUnknownEmailError] =
    useState<boolean>(false);
  const [showApiError, setShowApiError] = useState<boolean>(false);

  const onSubmit = (data) => {
    const emailAddress: string = useExistingEmail
      ? existingUser.email
      : data.emailAddress;

    setEmailAddress(emailAddress);

    // We need to look up the Auth0 organisation id.  This is an async call and the return value is handled by a side effect below
    organisationsWithSpecifiedEmailDomain({
      variables: { email: emailAddress },
    });
  };

  const redirectToAuth0Login = (
    emailAddress: string,
    organisationId?: string,
  ) => {
    // Redirect to auth0. Email and orgId are provided so users doesn't need to enter them.
    // appState contains the target path that will be used to take the user to the correct place
    // when the redirect happens.
    loginWithRedirect({
      login_hint: emailAddress,
      organization: organisationId,
      // appState: {
      //     returnTo: targetPath,
      // },
    });
  };

  const [searchParams] = useSearchParams();
  const invitation = searchParams.get("invitation");
  const organization = searchParams.get("organization");
  const organization_name = searchParams.get("organization_name");

  const isInvitation = invitation && organization && organization_name;

  // Invitation redirect side effect
  useEffect(() => {
    if (isInvitation) {
      loginWithRedirect();
    }
  }, [isInvitation, loginWithRedirect]);

  useEffect(() => {
    if (called && data) {
      if (
        data.organisationsWithSpecifiedEmailDomain?.organisations?.length > 0
      ) {
        let organisationId: string;

        // Get the Auth0 organisation id from the query results
        // If there is more than one organisation returned, then we don't set the organisation id in state
        // so the user is free to choose the organisation from the Auth0 universal login screen
        if (
          data.organisationsWithSpecifiedEmailDomain.organisations.length === 1
        ) {
          organisationId =
            data.organisationsWithSpecifiedEmailDomain.organisations[0]
              .externalDirectory?.organisationId;

          // update user organisation state
          dispatch(authSlice.actions.setUserOrganisationId(organisationId));
        } else {
          dispatch(authSlice.actions.setUserOrganisationId(null));
        }

        // Update user state
        dispatch(authSlice.actions.setUserEmail(emailAddress));

        // Redirect to Auth0
        redirectToAuth0Login(emailAddress, organisationId);
      } else {
        setShowUnknownEmailError(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, data, loginWithRedirect, dispatch]);

  return (
    <div className="login-page">
      {showApiError && (
        <ErrorCollection
          header="There was a problem querying the server"
          errorMessages={extractApolloErrorMessages(error)}
          onClose={() => setShowApiError(false)}
        />
      )}
      {showUnknownEmailError && (
        <Error
          header="Unknown email address"
          errorMessage={`The specified email address does not exist, please contact the C3 Operations Team (${SUPPORT_EMAIL}) to setup your access`}
          onClose={() => setShowUnknownEmailError(false)}
        />
      )}
      {!isInvitation && (
        <main className="login-box-container">
          <section>
            <div className="login-box">
              <header className="login-header">
                <img src={logo} alt="C3 Post Trade" height="52px" />
                <h1>Welcome</h1>
                <div>
                  <p>Continue to C3 Post Trade</p>
                </div>
              </header>
              <div className="login-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  {formMode === FormMode.ExistingUser && (
                    <Form.Group className="login-form-group">
                      <Form.Check
                        type="radio"
                        name="useExistingEmail"
                        id="useExistingEmail-true"
                        label={`Log in as ${existingUser.email}`}
                        checked={useExistingEmail}
                        onChange={(_) => setUseExistingEmail(true)}
                      ></Form.Check>
                      <Form.Check
                        type="radio"
                        name="useExistingEmail"
                        id="useExistingEmail-false"
                        label={`Log in using another email`}
                        checked={!useExistingEmail}
                        onChange={(_) => setUseExistingEmail(false)}
                      ></Form.Check>
                    </Form.Group>
                  )}
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    disabled={useExistingEmail}
                    {...register("emailAddress", {
                      validate: (value) => {
                        if (useExistingEmail || (value && value?.length > 0)) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    })}
                  ></Form.Control>
                  {errors.emailAddress && (
                    <span className="error">Email address is required</span>
                  )}
                  <button type="submit" className="login-button">
                    Continue
                  </button>
                </Form>
              </div>
            </div>
          </section>
        </main>
      )}
    </div>
  );
};
