import { FormFieldBox, FormFieldBoxBaseProps } from "./FormFieldBox";
import { Form } from "react-bootstrap";
import { useFormContext, RegisterOptions } from "react-hook-form";

interface FormSelectorFieldProps extends FormFieldBoxBaseProps {
  defaultValue: string;
  options: Array<{ value: any; label: string }>;
  validate?: RegisterOptions["validate"];
}

export function FormSelectorField({
  id,
  label,
  options,
  defaultValue,
  validate,
}: FormSelectorFieldProps) {
  const { register } = useFormContext();

  return (
    <FormFieldBox id={id} label={label}>
      <Form.Select
        id={id}
        defaultValue={defaultValue}
        className="form-selector-field"
        {...register(id, { validate })}
      >
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Form.Select>
    </FormFieldBox>
  );
}
