import { MenuItem } from "../../types/MenuItem";

export const findMenuItemFromExternalPath = (
  menu: MenuItem[],
  externalPath: string,
): [MenuItem, MenuItem] => {
  let menuItem: MenuItem;
  let parentMenuItem: MenuItem;

  menu.some((item) => {
    if (item.externalPath === externalPath) {
      menuItem = item;
      return true;
    }
    if (item.items) {
      menuItem = findMenuItemFromExternalPath(item.items, externalPath)[0];
      if (menuItem) {
        parentMenuItem = item;
        return true;
      }
    }
    return false;
  });

  return [menuItem, parentMenuItem];
};
