import { IHeaderParams } from "ag-grid-community";

export class GridRowSelectionColumnHeader {
  agParams: IHeaderParams;
  element: HTMLDivElement;
  selectAllButton: HTMLAnchorElement;

  onButtonClick = () => {
    const selectedRows = this.agParams.api.getSelectedNodes();
    const areRowsSelected = selectedRows.length > 0;

    if (areRowsSelected) {
      this.agParams.api.deselectAllFiltered();
    } else {
      this.agParams.api.selectAllFiltered();
    }
  };

  init(agParams: IHeaderParams) {
    this.agParams = agParams;

    this.element = getElement();
    this.selectAllButton = getButton(this.onButtonClick);

    this.element.appendChild(this.selectAllButton);
  }

  refresh(agParams: IHeaderParams) {
    this.agParams = agParams;
    return this.element;
  }

  getGui() {
    return this.element;
  }

  destroy() {
    this.selectAllButton.removeEventListener("click", this.onButtonClick);
  }
}

function getElement() {
  const element = document.createElement("div");

  element.style.display = "flex";
  element.style.justifyContent = "center";
  element.style.width = "100%";

  return element;
}

function getButton(onClickEventHandler: (e: MouseEvent) => void) {
  const selectAllButton = document.createElement("a");

  selectAllButton.innerText = "All";
  selectAllButton.style.textDecoration = "underline";
  selectAllButton.addEventListener("click", onClickEventHandler);

  return selectAllButton;
}
