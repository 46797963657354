import { useEffect, useRef, useState, RefObject } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs, { Dayjs } from "dayjs";
import {
  FAILS_SUMMARY_QUERY,
  FAILS_DETAIL_QUERY,
  FAILS_SUMMARY_RECORD_SET_QUERY,
  UPDATE_RECORD_MUTATION,
  RECORD_UPDATE_RESPONSES_QUERY,
} from "./graphql";
import { extractApolloErrorMessages } from "../../utils/graphql/extractApolloErrorMessages";
import { usePrevious } from "../../utils/hooks/usePrevious";
import { updateFailsRecord } from "./cache/updateFailsRecord";
import { config } from "../../utils/configuration/settings";
import { Grid } from "../../components/Grid";
import { CellValue, pendingStates } from "../../components/Grid.config";
import { GridLowerTabs } from "../../components/GridLowerTabs";
import { NewDataAvailableAlert } from "../../components/NewDataAvailableAlert";
import { ErrorCollection } from "../../components/ErrorCollection";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { Loading } from "../../components/Loading";
import "./fails.scss";
import { GET_CONFIGURATION_QUERY } from "../../graphql";

const FALLBACK_DATA_FETCH_POLLING_INTERVAL = 60000; // 60 secs
const FALLBACK_UPDATE_RESPONSE_POLLING_INTERVAL = 2000; // 2 secs
const WHEN_LAST_POLLED_FOR_RECORD_UPDATE_RESPONSES_LOCAL_STORAGE_KEY =
  "WhenLastPolledForRecordUpdateResponses";

interface SummaryProps {
  gridRef?: RefObject<any>;
}

function getNoteUpdate(fields: Array<{ name: string; value: string }>) {
  let isNoteUpdated = false;
  let noteValue = null;

  fields.forEach((cell) => {
    const isUpdateExist = cell.name === "latestNote" && !!cell.value;

    if (isUpdateExist) {
      isNoteUpdated = true;
      noteValue = cell.value;
    }
  });

  return {
    fields: isNoteUpdated
      ? fields.filter((field) => field.name !== "latestNote")
      : fields,
    noteValue,
  };
}

export const Summary = ({ gridRef }: SummaryProps): JSX.Element => {
  // Get settings...TODO, the settings should be passed down as React context
  const settings = config.get();

  // User context
  const { user, isAuthenticated } = useAuth0();

  // State
  const [recordSetWhenLastUpdated, setRecordSetWhenLastUpdated] =
    useState<Dayjs>(null);
  const [isNewDataAvailable, setIsNewDataAvailable] = useState(false);
  const [
    disableNotificationOfNewDataAvailable,
    setDisableNotificationOfNewDataAvailable,
  ] = useState(false);
  const [areAnyPendingRecordUpdates, setAreAnyPendingRecordUpdates] =
    useState(false);
  const [areAnyRecordUpdateErrors, setAreAnyRecordUpdateErrors] =
    useState(false);
  const [recordUpdateErrors, setRecordUpdateErrors] = useState<Array<string>>(
    [],
  );
  const [recordUpdatePollingIntervalId, setRecordUpdatePollingIntervalId] =
    useState(null);

  // Variable and custom hook that stores the previous state of recordSetWhenLastUpdated
  const prevRecordSetWhenLastUpdated: Dayjs = usePrevious(
    recordSetWhenLastUpdated,
  );

  // TODO...using this state variable for the initial query for record update responses, but we don't want this to
  // change (as that re-executes the query immediately, instead of using the polling interval)
  // Maybe use useRef instead?
  // eslint-disable-next-line
  const [
    initialWhenLastPolledForRecordUpdateResponses,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setInitialWhenLastPolledForRecordUpdateResponses,
  ] = useState(() => {
    // Load initial timestamp from local storage (if exists)
    let timestamp: Dayjs = null;
    const persistedWhenLastPolledForRecordUpdateResponses =
      localStorage.getItem(
        WHEN_LAST_POLLED_FOR_RECORD_UPDATE_RESPONSES_LOCAL_STORAGE_KEY,
      );
    if (persistedWhenLastPolledForRecordUpdateResponses) {
      timestamp = dayjs(persistedWhenLastPolledForRecordUpdateResponses);
    }
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] Initial whenLastPolledForRecordUpdateResponses = ${timestamp?.toISOString()}`,
    );
    return timestamp;
  });
  const [
    whenLastPolledForRecordUpdateResponses,
    setWhenLastPolledForRecordUpdateResponses,
  ] = useState(null);
  const whenLastPolledForRecordUpdateResponsesRef = useRef(
    whenLastPolledForRecordUpdateResponses,
  );
  whenLastPolledForRecordUpdateResponsesRef.current =
    whenLastPolledForRecordUpdateResponses;

  // GraphQL queries and mutations

  const apolloClient = useApolloClient();

  const { data: moduleAccessData, loading: isLoadingModuleAccess } = useQuery(
    GET_CONFIGURATION_QUERY,
    {
      variables: {
        namespace: user
          ? settings.namespace +
            "/" +
            user["https://c3posttrade.com/subscriptionId"]
          : null,
        configKey: "moduleAccess",
      },
      skip:
        !isAuthenticated ||
        (user && !user["https://c3posttrade.com/subscriptionId"]),
    },
  );

  // Query record set data
  const { data, loading, error, refetch } = useQuery(FAILS_SUMMARY_QUERY, {
    skip: !moduleAccessData,
    variables: {
      recordSetId:
        moduleAccessData?.namespace?.configuration?.[0]?.value?.filter(
          (_) => _.module === "fails",
        )[0]?.recordSetId,
    },
  });

  // Query for record set updates
  const {
    data: recordSetPollingData,
    error: recordSetPollingError,
    startPolling,
    stopPolling,
  } = useQuery(FAILS_SUMMARY_RECORD_SET_QUERY, {
    // Skip running query until FAILS_SUMMARY_QUERY has returned data
    skip: !data || !moduleAccessData,
    // Poll at specified interval
    pollInterval:
      settings.defaultDataFetchPollingInterval ??
      FALLBACK_DATA_FETCH_POLLING_INTERVAL,
    // Always fetch from the server and don't save the result in the cache (the actual data fetch will do that)
    fetchPolicy: "no-cache",
    variables: {
      recordSetId:
        moduleAccessData?.namespace?.configuration?.[0]?.value?.filter(
          (_) => _.module === "fails",
        )[0]?.recordSetId,
    },
  });

  // Query for record update responses
  const {
    data: recordUpdateResponsesData,
    error: recordUpdateResponsesError,
    refetch: refetchRecordUpdateResponses,
    variables: recordUpdateResponsesVariables,
  } = useQuery(RECORD_UPDATE_RESPONSES_QUERY, {
    variables: {
      whenUserLastPolled:
        initialWhenLastPolledForRecordUpdateResponses?.toISOString(),
    },
    // Skip running query until FAILS_SUMMARY_QUERY has returned data
    skip: !data,
    // Always fetch from the server
    fetchPolicy: "network-only",
  });

  // Update record mutation
  const [
    updateRecord,
    { data: updateRecordResponse, error: updateRecordError },
  ] = useMutation(UPDATE_RECORD_MUTATION);

  // Whenever data changes, set recordSetWhenLastUpdated if the date is newer
  useEffect(() => {
    console.debug(
      `[${dayjs().format("HH:mm:ss")}] FailsSummary side effect [data]`,
    );

    if (data?.failsSummary?.whenLastUpdated) {
      const whenLastUpdated = dayjs(data.failsSummary.whenLastUpdated);
      if (whenLastUpdated > recordSetWhenLastUpdated) {
        console.debug(
          `[${dayjs().format(
            "HH:mm:ss",
          )}] recordSetWhenLastUpdated changed, whenLastUpdated = ${
            data.failsSummary.whenLastUpdated
          }`,
        );
        setDisableNotificationOfNewDataAvailable(true);
        setRecordSetWhenLastUpdated(whenLastUpdated);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  // Whenever recordSetPollingData changes, set recordSetWhenLastUpdated
  useEffect(() => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] FailsSummary side effect [recordSetPollingData]: recordSetPollingData = ${JSON.stringify(
        recordSetPollingData,
      )}`,
    );

    if (recordSetPollingData?.failsSummary?.whenLastUpdated) {
      const whenLastUpdated = dayjs(
        recordSetPollingData.failsSummary.whenLastUpdated,
      );
      if (whenLastUpdated > recordSetWhenLastUpdated) {
        console.debug(
          `[${dayjs().format(
            "HH:mm:ss",
          )}] recordSetWhenLastUpdated changed, whenLastUpdated = ${
            recordSetPollingData.failsSummary.whenLastUpdated
          }`,
        );
        setRecordSetWhenLastUpdated(whenLastUpdated);
      }
    }
    // eslint-disable-next-line
  }, [recordSetPollingData]);

  // Whenever updateRecordResponse changes, handle response
  useEffect(() => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] FailsSummary side effect [updateRecordResponse]: updateRecordResponse = ${JSON.stringify(
        updateRecordResponse,
      )}`,
    );

    if (updateRecordResponse?.updateRecord) {
      // Update Apollo client cache
      updateFailsRecord(
        apolloClient,
        updateRecordResponse.updateRecord.recordId,
        updateRecordResponse.updateRecord.result,
        updateRecordResponse.updateRecord.fields.map((field) => {
          return {
            name: field.name,
            value: field.value,
          };
        }),
        updateRecordResponse.updateRecord.noteText,
      );

      // Check for any pending responses
      if (pendingStates.includes(updateRecordResponse.updateRecord.result)) {
        setAreAnyPendingRecordUpdates(true);
      }

      // Check if the result is a failure
      if (updateRecordResponse.updateRecord.result === "FAILURE") {
        setAreAnyRecordUpdateErrors(true);
        setRecordUpdateErrors(updateRecordResponse.updateRecord.messages);
      }
    }
    // eslint-disable-next-line
  }, [updateRecordResponse]);

  // Whenever recordUpdateResponsesData changes, set whenLastPolledForRecordUpdateResponses and areAnyPendingRecordUpdates
  useEffect(() => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] FailsSummary side effect [recordUpdateResponsesData, apolloClient]: recordUpdateResponsesData = ${JSON.stringify(
        recordUpdateResponsesData,
      )}, apolloClient = ${apolloClient}`,
    );

    // Set when last polled from server timestamp on response
    if (recordUpdateResponsesData?.recordUpdateResponses?.timestamp) {
      console.debug(
        `[${dayjs().format(
          "HH:mm:ss",
        )}] RECORD_UPDATE_RESPONSES_QUERY completed, timestamp used: ${
          recordUpdateResponsesVariables.whenUserLastPolled
        }, data returned: ${JSON.stringify(recordUpdateResponsesData)}`,
      );

      setWhenLastPolledForRecordUpdateResponses(
        dayjs(recordUpdateResponsesData.recordUpdateResponses.timestamp),
      );
    }

    if (recordUpdateResponsesData?.recordUpdateResponses?.results) {
      // Check for any pending responses
      if (recordUpdateResponsesData.recordUpdateResponses.results.length > 0) {
        setAreAnyPendingRecordUpdates(true);
      } else {
        setAreAnyPendingRecordUpdates(false);
      }

      // Check for any errors
      if (
        recordUpdateResponsesData.recordUpdateResponses.results.find(
          (response) => response.result === "FAILURE",
        )
      ) {
        setAreAnyRecordUpdateErrors(true);
        setRecordUpdateErrors(
          recordUpdateResponsesData.recordUpdateResponses.results
            .filter((response) => response.result === "FAILURE")
            .flatMap((response) => response.messages),
        );
      } else {
        setAreAnyRecordUpdateErrors(false);
        setRecordUpdateErrors([]);
      }

      // Check for any successfull results (and refresh data for each)
      if (
        recordUpdateResponsesData.recordUpdateResponses.results.find(
          (response) => response.result === "SUCCESS",
        )
      ) {
        // Call the detail query to refresh the data for each successfull record
        recordUpdateResponsesData.recordUpdateResponses.results
          .filter((response) => response.result === "SUCCESS")
          .forEach((response) => {
            console.debug(
              `[${dayjs().format(
                "HH:mm:ss",
              )}] GetFailsDetail called for recordId: ${response.recordId}`,
            );

            apolloClient.query({
              query: FAILS_DETAIL_QUERY,
              // Always fetch from the server
              fetchPolicy: "network-only",
              variables: {
                id: response.recordId,
                recordSetId:
                  moduleAccessData?.namespace?.configuration?.[0]?.value?.filter(
                    (_) => _.module === "fails",
                  )[0]?.recordSetId,
              },
            });
          });

        // Ignore the next time that recordSetWhenLastUpdated triggers an alert or new data available
        setDisableNotificationOfNewDataAvailable(true);
      }
    }

    // eslint-disable-next-line
  }, [recordUpdateResponsesData, apolloClient]);

  // Whenever recordUpdateResponsesData changes, update the Apollo client cache with the record update state
  useEffect(() => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] FailsSummary side effect [recordUpdateResponsesData]: recordUpdateResponsesData = ${JSON.stringify(
        recordUpdateResponsesData,
      )}`,
    );

    // Update the state of the records in the Apollo client cache
    recordUpdateResponsesData?.recordUpdateResponses?.results?.forEach(
      (response) => {
        updateFailsRecord(
          apolloClient,
          response.recordId,
          response.result,
          response.fields.map((field) => {
            return {
              name: field.name,
              value: field.value,
            };
          }),
          response.noteText,
        );
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordUpdateResponsesData]);

  // Whenever whenLastPolledForRecordUpdateResponses state changes, persist state
  useEffect(() => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] FailsSummary side effect [whenLastPolledForRecordUpdateResponses]: whenLastPolledForRecordUpdateResponses = ${whenLastPolledForRecordUpdateResponses?.toISOString()}`,
    );

    if (whenLastPolledForRecordUpdateResponses) {
      // Persist to local storage
      localStorage.setItem(
        WHEN_LAST_POLLED_FOR_RECORD_UPDATE_RESPONSES_LOCAL_STORAGE_KEY,
        whenLastPolledForRecordUpdateResponses.toISOString(),
      );
    }
  }, [whenLastPolledForRecordUpdateResponses]);

  // Whenever areAnyPendingRecordUpdates or areAnyRecordUpdateErrors changes, either start or stop polling accordingly
  useEffect(() => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] FailsSummary side effect [areAnyPendingRecordUpdates, areAnyRecordUpdateErrors]: areAnyPendingRecordUpdates = ${areAnyPendingRecordUpdates}, areAnyUpdateErrors = ${areAnyRecordUpdateErrors}`,
    );
    let intervalId = null;

    // If any pending updates and no errors, start polling if not already
    if (
      areAnyPendingRecordUpdates &&
      !areAnyRecordUpdateErrors &&
      !recordUpdatePollingIntervalId
    ) {
      console.debug(
        `[${dayjs().format(
          "HH:mm:ss",
        )}] Start polling for record update responses`,
      );

      intervalId = setInterval(() => {
        console.debug(
          `[${dayjs().format(
            "HH:mm:ss",
          )}] Refetching RECORD_UPDATE_RESPONSES_QUERY, timestamp used: ${whenLastPolledForRecordUpdateResponsesRef.current.toISOString()}`,
        );
        refetchRecordUpdateResponses({
          whenUserLastPolled:
            whenLastPolledForRecordUpdateResponsesRef.current.toISOString(),
        });
      }, settings.defaultUpdateResponsePollingInterval ?? FALLBACK_UPDATE_RESPONSE_POLLING_INTERVAL);

      setRecordUpdatePollingIntervalId(intervalId);
    } else if (
      (!areAnyPendingRecordUpdates || areAnyRecordUpdateErrors) &&
      recordUpdatePollingIntervalId
    ) {
      console.debug(
        `[${dayjs().format(
          "HH:mm:ss",
        )}] Stop polling for record update responses`,
      );

      clearInterval(recordUpdatePollingIntervalId);

      setRecordUpdatePollingIntervalId(null);
    }

    // Stop polling when component unloads
    return () => clearInterval(intervalId);

    // eslint-disable-next-line
  }, [areAnyPendingRecordUpdates, areAnyRecordUpdateErrors]);

  // Whenever recordSetWhenLastUpdated changes, then set isNewDataAvailable
  useEffect(() => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] FailsSummary side effect [recordSetWhenLastUpdated]: recordSetWhenLastUpdated = ${JSON.stringify(
        recordSetWhenLastUpdated,
      )}, prevRecordSetWhenLastUpdated = ${JSON.stringify(
        prevRecordSetWhenLastUpdated,
      )}, disableNotificationOfNewDataAvailable = ${disableNotificationOfNewDataAvailable}`,
    );

    if (
      recordSetWhenLastUpdated &&
      prevRecordSetWhenLastUpdated &&
      !disableNotificationOfNewDataAvailable
    ) {
      setIsNewDataAvailable(
        recordSetWhenLastUpdated > prevRecordSetWhenLastUpdated,
      );

      console.debug(
        `[${dayjs().format("HH:mm:ss")}] Set isNewDataAvailable = ${
          recordSetWhenLastUpdated > prevRecordSetWhenLastUpdated
        }`,
      );
    }

    setDisableNotificationOfNewDataAvailable(false);

    // eslint-disable-next-line
  }, [recordSetWhenLastUpdated]);

  // Whenever isNewDataAvailable is true then stop polling for updated data (while the popup is open)
  useEffect(() => {
    console.debug(
      `FailsSummary side effect [isNewDataAvailable]: isNewDataAvailable = ${isNewDataAvailable}`,
    );
    if (moduleAccessData) {
      if (isNewDataAvailable) {
        stopPolling();
        console.debug(
          `[${dayjs().format("HH:mm:ss")}] Stop polling for new data`,
        );
      } else {
        startPolling(
          settings.defaultDataFetchPollingInterval ??
            FALLBACK_DATA_FETCH_POLLING_INTERVAL,
        );
        console.debug(
          `[${dayjs().format("HH:mm:ss")}] Start polling for new data`,
        );
      }
    }

    // eslint-disable-next-line
  }, [isNewDataAvailable]);

  // When a grid cell is updated, update the Apollo client cache
  const updateDataFields = (id: string, cellValues: CellValue[]) => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] FailsSummary.updateDataField called, id: ${id}, cellValue: ${JSON.stringify(
        cellValues,
      )}`,
    );

    updateFailsRecord(
      apolloClient,
      id,
      "PENDING",
      cellValues
        .filter((cell) => cell.fieldName !== "latestNote")
        .map((cell) => ({
          name: cell.fieldName,
          value: cell.value,
        })),
      cellValues.find((cell) => cell.fieldName === "latestNote")?.value,
    );
  };

  // When a grid row update is triggered, submit the graphql mutation to the server
  const onRowValueChanged = (
    id: string,
    updatedCells: CellValue[],
    concurrencyStamp: string,
  ) => {
    console.debug(
      `[${dayjs().format(
        "HH:mm:ss",
      )}] FailsSummary.onRowValueChanged called, id: ${id}, updatedCells: ${JSON.stringify(
        updatedCells,
      )}, concurrencyStamp: ${concurrencyStamp}`,
    );

    const processFields = updatedCells.map(({ fieldName, value }) => ({
      name: fieldName,
      value,
    }));

    const { fields, noteValue } = getNoteUpdate(processFields);

    // Call the mutation function to post the update
    updateRecord({
      variables: {
        recordId: id,
        concurrencyStamp: concurrencyStamp,
        fields,
        noteText: noteValue,
      },
    });
  };

  return (
    <ErrorBoundary>
      <div className="main-body">
        <div className="main-grid">
          {(loading || isLoadingModuleAccess) && (
            <Loading width={75} height={75} />
          )}
          {error && (
            <ErrorCollection
              header="There was a problem fetching the data"
              errorMessages={extractApolloErrorMessages(error)}
            />
          )}
          {recordSetPollingError && (
            <ErrorCollection
              header="There was a problem polling for updated data"
              errorMessages={extractApolloErrorMessages(recordSetPollingError)}
            />
          )}
          {updateRecordError && (
            <ErrorCollection
              header="There was a problem requesting the record update"
              errorMessages={extractApolloErrorMessages(updateRecordError)}
            />
          )}
          {areAnyRecordUpdateErrors && (
            <ErrorCollection
              header="Error(s) while performing update(s)"
              errorMessages={recordUpdateErrors}
              onClose={() => setAreAnyRecordUpdateErrors(false)}
            />
          )}
          {recordUpdateResponsesError && (
            <ErrorCollection
              header="There was a problem polling for record update responses"
              errorMessages={extractApolloErrorMessages(
                recordUpdateResponsesError,
              )}
            />
          )}
          {data && (
            <Grid
              gridRef={gridRef}
              type="FailsSummary"
              heading="Reporting and Tracking"
              subHeading="Pending and Failed Trade Settlements"
              data={data.failsSummary.records}
              rowStateColumnName="recordUpdateState"
              updateDataFields={updateDataFields}
              onRowValueChanged={onRowValueChanged}
              onRefreshClicked={refetch}
              userId={user?.sub}
              enableEditing
              enableNotes
            />
          )}
          {isNewDataAvailable && (
            <NewDataAvailableAlert
              onRefresh={() => {
                setIsNewDataAvailable(false);
                refetch();
              }}
              onClose={() => setIsNewDataAvailable(false)}
            />
          )}
        </div>
        <GridLowerTabs />
      </div>
    </ErrorBoundary>
  );
};
