/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from "react";
import type { ColumnApi, GridApi } from "ag-grid-community";

export interface IGridApiContext {
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
}

export const GridApiContext = createContext<IGridApiContext>(null);

export const initialContext: IGridApiContext = {
  gridApi: null,
  gridColumnApi: null,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGridApis = () => {
  const gridApi = useContext(GridApiContext);

  return gridApi;
};
