import { useContext, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ReactBootstrapNavbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { MenuItem } from "../types/MenuItem";
import { config } from "../utils/configuration/settings";
import { constructUrlFromMenuItem } from "../utils/menu/constructUrlFromMenuItem";
import { NavigationContext } from "../context/NavigationContext";
import "./Navbar.scss";

export const Navbar = (): JSX.Element => {
  const settings = config.get();

  const navigationState = useContext(NavigationContext);
  const location = useLocation();
  const [openedDropdown, setOpenedDropdown] = useState<number>(null);

  const showDropdown = (index: number) => {
    setOpenedDropdown(index);
  };

  const hideDropdown = () => {
    setOpenedDropdown(null);
  };

  function isAnyChildActive(parentRoute: string, menuItems: MenuItem[]) {
    let anyChildActive = false;

    menuItems?.forEach((item) => {
      if (!anyChildActive) {
        // We exclude the embed route (if applicable)
        anyChildActive =
          parentRoute + item.route === location.pathname.replace("/embed", "");
      }
    });

    return anyChildActive;
  }

  function isAnyChildEnabled(menuItems: MenuItem[]) {
    let anyChildEnabled = false;

    menuItems?.forEach((item) => {
      if (!anyChildEnabled) {
        anyChildEnabled = !item.disabled;
      }
    });

    return anyChildEnabled;
  }

  // TODO...make nesting infinite
  return (
    <div className="top-nav">
      <ReactBootstrapNavbar variant="light" expand="lg" className="p-0 m-0">
        <NavbarToggle aria-controls="basic-navbar-nav" />
        <NavbarCollapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {navigationState.menu?.map((menuItem, menuIndex) => {
              const menuItemDisabled =
                menuItem.disabled || !isAnyChildEnabled(menuItem.items);

              return (
                <Dropdown
                  as={NavItem}
                  id={`nav-dropdown-${menuIndex}`}
                  key={`nav-dropdown-${menuIndex}`}
                  show={menuIndex === openedDropdown}
                  onMouseEnter={(e) => {
                    if (!menuItemDisabled) {
                      showDropdown(menuIndex);
                    }
                  }}
                  onMouseLeave={(e) => hideDropdown()}
                >
                  <Dropdown.Toggle
                    as={NavLink}
                    active={
                      menuItem.route === location.pathname ||
                      isAnyChildActive(menuItem.route, menuItem.items)
                    }
                    disabled={menuItemDisabled}
                  >
                    <i className={menuItem.icon + " fs-4"}></i>
                    {menuItem.text}
                  </Dropdown.Toggle>
                  {menuItem.items?.length > 0 && (
                    <Dropdown.Menu show={menuItem.items?.length > 0}>
                      {menuItem.items?.map((subMenuItem, itemIndex) => {
                        return (
                          <LinkContainer
                            to={constructUrlFromMenuItem(
                              subMenuItem,
                              menuItem,
                              settings,
                            )}
                            key={`nav-item-${menuIndex}-${itemIndex}`}
                            id={`nav-item-${menuIndex}-${itemIndex}`}
                          >
                            <Dropdown.Item
                              disabled={subMenuItem.disabled}
                              active={
                                // We exclude the embed route (if applicable)
                                menuItem.route + subMenuItem.route ===
                                location.pathname.replace("/embed", "")
                              }
                            >
                              {subMenuItem.text}
                            </Dropdown.Item>
                          </LinkContainer>
                        );
                      })}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              );
            })}
          </Nav>
        </NavbarCollapse>
      </ReactBootstrapNavbar>
    </div>
  );
};
