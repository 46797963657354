import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkContainer } from "react-router-bootstrap";
import Cookies from "js-cookie";
import { USER_IMPERSONATION_COOKIE_KEY } from "../constants";
import { config } from "../utils/configuration/settings";
import { Logo } from "./Logo";
import { EnvironmentLabel } from "./EnvironmentLabel";
import { Breadcrumb } from "./Breadcrumb";
import { Navbar } from "./Navbar";
import { UserProfileMenu } from "./UserProfileMenu";
import { SubMenu } from "./SubMenu";
import { ImpersonationBanner } from "./ImpersonationBanner";
import { TrustedRelationshipItem } from "./LoginAsClientModal";
import "./AppHeader.scss";

interface AppHeaderProps {
  firstName?: string;
  lastName?: string;
  organisationName?: string;
  impersonatedOrganisationName?: string;
  trustedRelationships: TrustedRelationshipItem[];
}

const logoutOfEmbeddedApplicationInBackground = (embeddedLogoutUrl: string) => {
  if (window.Worker) {
    const embeddedApplicationLogoutWorker = new Worker(
      process.env.PUBLIC_URL + "/embeddedApplicationLogoutWorker.js",
    );
    embeddedApplicationLogoutWorker.postMessage([embeddedLogoutUrl]);
  }
};

export const AppHeader = (props: AppHeaderProps): JSX.Element => {
  const settings = config.get();

  const { isAuthenticated, logout: auth0Logout } = useAuth0();

  const logout = (logoutEntirely: boolean) => {
    // Log out of embedded appplications (in background)
    if (Array.isArray(settings.embeddedApplications)) {
      settings.embeddedApplications.forEach((embeddedApplication) => {
        logoutOfEmbeddedApplicationInBackground(
          embeddedApplication.embeddedLogoutUrl,
        );
      });
    }

    // Clear impersonation request
    Cookies.remove(USER_IMPERSONATION_COOKIE_KEY, { path: "/" });

    if (logoutEntirely) {
      // Logout of Auth0
      auth0Logout({ returnTo: window.location.origin });
    } else {
      window.location.assign("/");
    }

    return false;
  };

  return (
    <React.Fragment>
      {isAuthenticated && (
        <header className="app-header">
          <Container fluid>
            <ImpersonationBanner
              organisationName={props.impersonatedOrganisationName}
            />
            <Row className="align-items-center">
              <EnvironmentLabel
                environment={settings.environment}
                application={settings.applicationName}
              />
              <Col className="col-4 pt-2 pb-2">
                <LinkContainer to="/">
                  <Logo width={153} height={40} />
                </LinkContainer>
              </Col>
              <Col className="text-center">
                <Breadcrumb />
              </Col>
              <Col className="col-4">
                <div className="d-flex flex-row justify-content-end">
                  <div className="d-flex flex-column">
                    <div className="text-center">{`${props.firstName} ${props.lastName}`}</div>
                    <div className="text-center">{props.organisationName}</div>
                  </div>
                  <div className="ms-5 me-3">
                    <UserProfileMenu
                      logout={logout}
                      trustedRelationships={props.trustedRelationships}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="menu">
                <Navbar />
                <SubMenu />
              </Col>
            </Row>
          </Container>
        </header>
      )}
    </React.Fragment>
  );
};
