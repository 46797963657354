export interface EmbeddedApplication {
  id: number;
  key: string;
  embeddedHost: string;
  embeddedLoginUrl: string;
  embeddedLogoutUrl: string;
}

export interface AppSettings {
  applicationName: string;
  applicationId: string;
  namespace: string;
  environment: "DEV" | "STAGING" | "PROD";
  authDomain: string;
  authClientId: string;
  embeddedApplications?: EmbeddedApplication[];
  defaultDataFetchPollingInterval?: number;
  defaultUpdateResponsePollingInterval?: number;
  impersonationExpiryInSeconds: number;
  enableRecordsRoutes?: boolean;
}

export const defaultSettings: AppSettings = {
  applicationName: "Default",
  applicationId: "default",
  namespace: "application/default",
  environment: "DEV",
  authDomain: "dev-c3posttrade.eu.auth0.com",
  authClientId: "XVrWvCaJxxIP04hapYxfeFJgqiVHDMBA",
  embeddedApplications: [
    {
      id: 1,
      key: "Decisions",
      embeddedHost: "http://localhost:3001",
      embeddedLoginUrl: "http://localhost:8080/Login?ReturnUrl=%2F",
      embeddedLogoutUrl: "http://localhost:3001/Logout",
    },
  ],
  defaultDataFetchPollingInterval: 60000, // 60 secs
  defaultUpdateResponsePollingInterval: 2000, // 2 secs
  impersonationExpiryInSeconds: 7200, // 2 hours
  enableRecordsRoutes: false,
};

class Config {
  config: AppSettings = defaultSettings;
  loaded = false;

  public get(): AppSettings {
    if (!this.loaded) {
      throw new Error(
        "Config has not been loaded yet. Be sure to call the getter only after the config has been downloaded and set. Probable cause is accessing config in a static context.",
      );
    } else {
      return this.config;
    }
  }

  public set(value: AppSettings): void {
    if (!this.loaded) {
      this.config = value;
      this.loaded = true;
    } else {
      console.warn(
        "Config has already been loaded and now has been called a second time. This is probably not intended.",
      );
    }
  }
}

export const config = new Config();

export const configUrl = "/settings.json";
