import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Summary as CashMarginSummary } from "./pages/cash-margin/Summary";
import { Summary as FailsSummary } from "./pages/fails/Summary";
import { EmbeddedPage } from "./pages/EmbeddedPage";
import { RequireAuth } from "./utils/auth/RequireAuth";
import { usePersistAuthInfo } from "./utils/hooks/usePersistAuthInfo";
import { AppSettings } from "./utils/configuration/settings";
import NotFoundPage from "./pages/NotFoundPage";

export const AppRoutes = ({
  settings,
}: {
  settings: AppSettings;
}): JSX.Element => {
  usePersistAuthInfo();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      {settings.enableRecordsRoutes && (
        <Route
          path={"/cash-margin/*"}
          element={
            <RequireAuth settings={settings} component={CashMarginSummary} />
          }
        />
      )}
      {settings.enableRecordsRoutes && (
        <Route
          path={"/fails/*"}
          element={<RequireAuth settings={settings} component={FailsSummary} />}
        />
      )}

      <Route
        path="/embed/*"
        element={<RequireAuth settings={settings} component={EmbeddedPage} />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
