import { createContext } from "react";
import { MenuItem } from "../types/MenuItem";

export interface NavigationState {
  menu: MenuItem[];
  activeMenuItem: MenuItem;
  crumbs: string[];
}

export const NavigationContext = createContext<NavigationState>({
  menu: [],
  activeMenuItem: null,
  crumbs: [],
});
