import { RefObject, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  AgGridEvent,
  ColDef,
  ColGroupDef,
  EditableCallback,
  FilterChangedEvent,
  GridOptions,
  RowValueChangedEvent,
} from "ag-grid-community";
import { LicenseManager } from "ag-grid-enterprise";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { mergeDeepRight } from "ramda";
import { useAppDispatch, useAppSelector } from "../redux";
import { Selectors } from "../redux/selectors";
import { gridSlice } from "../redux/slices/gridSlice";
import { config } from "../utils/configuration/settings";
import { extractApolloErrorMessages } from "../utils/graphql/extractApolloErrorMessages";
import { DeepPartial } from "../utils/tests/MockReduxProvider";
import { serialiseColumnState } from "./grid/serialiseColumnState";
import { Button } from "./Button";
import { ErrorCollection } from "./ErrorCollection";
import { GridCellTooltip } from "./grid/components/GridCellTooltip";
import { agGridLicense } from "./Grid.license";
import {
  GridApiContext,
  IGridApiContext,
  initialContext,
} from "./GridApiContext";
import { GridRowEditorModal } from "./GridRowEditorModal";
import {
  CellValue,
  getDefaultGridOptions,
  pendingStates,
  rowSelectionColumn,
} from "./Grid.config";
import { useModal } from "./ModalBase";
import { PageHeading } from "./PageHeading";
import { Loading } from "./Loading";
import { GetGridConfig } from "../graphql";
import "./Grid.scss";

type GridProps = {
  type: string;
  heading?: string;
  subHeading?: string;
  userId: string;
  data: any[];
  rowStateColumnName?: string;
  // function to call to update the underlying data when a cell is updated (for example, when updating an immutable data store)
  updateDataFields?: (id: string, cellValue: CellValue[]) => void;
  // function to call when a row is updated
  onRowValueChanged?: (
    id: string,
    updatedCells: CellValue[],
    concurrencyStamp: string,
  ) => void;
  // function to call when the refresh button clicked
  onRefreshClicked?: any;
  // function to get the row id from a data row
  getRowId?: any;
  // only required for testing
  suppressColumnVirtualisation?: boolean;

  gridRef?: RefObject<AgGridReact>;

  enableEditing?: boolean;
  enableNotes?: boolean;

  gridOptions?: DeepPartial<GridOptions>;

  containerStyle?: React.CSSProperties;
};

const isColDef = (column: ColDef | ColGroupDef): column is ColDef => {
  return (column as ColDef).editable !== undefined;
};

LicenseManager.setLicenseKey(agGridLicense);

// TODO: I can't get this to work as component state
let updatedCells: Array<CellValue> = [];

export const Grid = (props: GridProps): JSX.Element => {
  // Get settings...TODO, the settings should be passed down as React context
  const settings = config.get();

  const dispatch = useAppDispatch();
  const { isOpen, openModal, closeModal } = useModal();
  const [gridApis, setGridApis] = useState<IGridApiContext>(initialContext);
  const [isGridReadOnly, setIsGridReadOnly] = useState(false);
  const isEditing = useAppSelector((state) =>
    Selectors.isGridEditing(props.type, state),
  );
  const [userHasOverriddenGridState, setUserHasOverriddenGridState] =
    useState(false);
  const [userHasOverriddenFilterState, setUserHasOverriddenFilterState] =
    useState(false);
  const [defaultGridState, setDefaultGridState] = useState(null);
  const [defaultFilterState, setDefaultFilterState] = useState(null);

  const userColumnStateKey = `columnState_${props.type}_${props.userId}`;
  const userFilterStateKey = `filterState_${props.type}_${props.userId}`;

  const rowStateColumnName = props.rowStateColumnName ?? "rowUpdateState";

  const enableSelectAllColumn = props.enableEditing && props.enableNotes;

  const {
    data: gridConfig,
    loading,
    error,
  } = GetGridConfig(settings.namespace, props.type);

  // Event handler that executes when the grid is ready
  const onGridReady = (params: AgGridEvent) => {
    if (
      gridConfig?.namespace?.configuration &&
      gridConfig.namespace.configuration.length > 1
    ) {
      console.log(
        `Multiple grid configurations returned for application: ${settings.namespace} and grid type: ${props.type}, defaulting to the first one`,
      );
    }

    // Set grid API's
    setGridApis({
      gridApi: params.api,
      gridColumnApi: params.columnApi,
    });

    // Set initial grid state
    dispatch(
      gridSlice.actions.setState({
        type: props.type,
        state: {
          selectedRows: [],
          isGridReady: true,
        },
      }),
    );

    console.log(`${props.type} grid ready`);

    // Override editable (boolean) columns with editable function instead
    // TODO...doesn't yet support column groupings (as it only looks at the top level)
    const columns = params.api.getColumnDefs();

    columns.forEach((column) => {
      if (isColDef(column) && column.editable) {
        column.editable = isCellEditable;
        column.headerTooltip =
          "Hint: you can double click the row to edit, press Enter or click outside of the row to update, or press Esc to cancel";
      }
    });

    if (enableSelectAllColumn) {
      // add checkbox column
      columns.unshift(rowSelectionColumn);
    }

    const areAllColumnsUneditable = columns.every(
      (col: ColDef) => !!col.editable === false,
    );
    if (areAllColumnsUneditable) {
      setIsGridReadOnly(true);
    }

    params.api.setColumnDefs(columns);

    // Set default grid state
    setDefaultGridState(
      serialiseColumnState(params.columnApi.getColumnState()),
    );
    setDefaultFilterState(JSON.stringify(params.api.getFilterModel()));

    // Apply user grid state (if exists)
    const userColumnStateJson = localStorage.getItem(userColumnStateKey);
    if (userColumnStateJson) {
      const userColumnState = JSON.parse(userColumnStateJson);
      if (userColumnState) {
        params.columnApi.applyColumnState({
          state: userColumnState,
          applyOrder: true,
        });
        setUserHasOverriddenGridState(true);
        // console.debug("Applied custom grid state");
      }
    }

    const userFilterStateJson = localStorage.getItem(userFilterStateKey);
    if (userFilterStateJson) {
      const userFilterState = JSON.parse(userFilterStateJson);
      if (userFilterState) {
        params.api.setFilterModel(userFilterState);
        setUserHasOverriddenFilterState(true);
        // console.debug("Applied custom grid filter");
      }
    }
  };

  const saveColumnState = (e: AgGridEvent) => {
    const currentGridState = serialiseColumnState(e.columnApi.getColumnState());

    if (currentGridState !== defaultGridState) {
      localStorage.setItem(userColumnStateKey, currentGridState);
      setUserHasOverriddenGridState(true);
    } else {
      localStorage.removeItem(userColumnStateKey);
      setUserHasOverriddenGridState(false);
    }
  };

  const saveFilterState = (e: FilterChangedEvent) => {
    const currentFilterState = JSON.stringify(e.api.getFilterModel());

    if (currentFilterState !== defaultFilterState) {
      localStorage.setItem(userFilterStateKey, currentFilterState);
      setUserHasOverriddenFilterState(true);
    } else {
      localStorage.removeItem(userFilterStateKey);
      setUserHasOverriddenFilterState(false);
    }
  };

  const onColumnMoved: GridOptions["onColumnMoved"] = saveColumnState;
  const onColumnPinned: GridOptions["onColumnPinned"] = saveColumnState;
  const onColumnResized: GridOptions["onColumnResized"] = saveColumnState;
  const onColumnRowGroupChanged: GridOptions["onColumnRowGroupChanged"] =
    saveColumnState;
  const onColumnVisible: GridOptions["onColumnVisible"] = saveColumnState;
  const onSortChanged: GridOptions["onSortChanged"] = saveColumnState;
  const onFilterChanged: GridOptions["onFilterChanged"] = saveFilterState;

  const onRowValueChanged = (event: RowValueChangedEvent) => {
    console.debug(
      `Grid.onRowValueChanged, updatedCells = ${JSON.stringify(updatedCells)}`,
    );

    // Call data update function, passing the updated cell values
    if (updatedCells.length > 0 && props.onRowValueChanged) {
      props.onRowValueChanged(
        event.data.id,
        updatedCells,
        event.data.concurrencyStamp,
      );
    }

    // Clear the updated cell state
    updatedCells = [];
  };

  const onRowSelected: GridOptions["onRowSelected"] = (e) => {
    // Set selected row state
    dispatch(
      gridSlice.actions.setState({
        type: props.type,
        state: {
          selectedRows: e.api.getSelectedRows(),
        },
      }),
    );
  };

  const resetFilters = () => {
    gridApis.gridApi.setFilterModel(null);
  };

  const resetGrid = () => {
    gridApis.gridColumnApi.resetColumnState();

    resetFilters();

    // This is a workaround for a bug in ag-grid when you reset row groupings, any row group columns that are added back in are added
    // to the end of the columns rather than preserving the original order.  A second reset seems to solve this.
    if (userHasOverriddenGridState) {
      gridApis.gridColumnApi.resetColumnState();
    }
  };

  const getRowId: GridOptions["getRowId"] = (params) =>
    props.getRowId ? props.getRowId(params.data) : params.data.id;

  const cellValueSetter: ColDef["valueSetter"] = (params) => {
    if (params.newValue !== params.oldValue) {
      if (props.updateDataFields) {
        // If an onCellValueChanged handler is provided, then call that handler to update the data (for example, if the grid is bound to an immutable data store)
        props.updateDataFields(
          props.getRowId ? props.getRowId(params.data) : params.data.id,
          [
            {
              fieldName: params.colDef.field,
              value: params.newValue,
            },
          ],
        );
      } else {
        // Otherwise, just update the data directly
        params.data = {
          ...params.data,
          [params.colDef.field]: params.newValue,
          rowUpdateState: "PENDING",
        };
      }

      // Add the field to the list of updated cells (used when firing the onRowUpdated event)
      updatedCells = [
        ...updatedCells,
        { fieldName: params.colDef.field, value: params.newValue },
      ];

      return true;
    }

    return false;
  };

  const isCellEditable: EditableCallback = (params) => {
    if (
      params.data &&
      pendingStates.includes(params.data[rowStateColumnName])
    ) {
      return false;
    }

    return true;
  };

  const gridOptionsBase: GridOptions = {
    ...getDefaultGridOptions({ rowStateColumnName }),
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      valueSetter: cellValueSetter,
      enableCellChangeFlash: true,
      tooltipComponent: GridCellTooltip,
    },
    rowClassRules: {
      "grid-row-pending": (params) => {
        return (
          params.data && pendingStates.includes(params.data[rowStateColumnName])
        );
      },
      "grid-row-error": (params) => {
        return params.data && params.data[rowStateColumnName] === "FAILURE";
      },
    },
  };

  const gridOptions = (
    props.gridOptions
      ? mergeDeepRight(gridOptionsBase, props.gridOptions)
      : gridOptionsBase
  ) as GridOptions;

  return (
    <GridApiContext.Provider value={gridApis}>
      {loading && <Loading width={75} height={75} />}
      {error && (
        <ErrorCollection
          header="There was a problem fetching the grid configuration"
          errorMessages={extractApolloErrorMessages(error)}
        />
      )}
      {gridConfig &&
        ((!gridConfig.namespace?.configuration && (
          <ErrorCollection
            header="There was a problem fetching the grid configuration"
            errorMessages={["No grid configuration found"]}
          />
        )) ||
          (gridConfig.namespace?.configuration && (
            <Container fluid className="height-100">
              <Row>
                <Col>
                  <PageHeading
                    heading={props.heading}
                    subHeading={props.subHeading}
                  />
                </Col>
                <Col>
                  <div className="grid-toolbar">
                    {props.enableEditing && (
                      <Button
                        text="Edit"
                        disabled={!isEditing || isGridReadOnly}
                        onClick={() => {
                          // Set grid editor modal mode
                          dispatch(
                            gridSlice.actions.setState({
                              type: props.type,
                              state: {
                                editorModalMode: "edit",
                              },
                            }),
                          );
                          openModal();
                        }}
                        className="m-1"
                      />
                    )}
                    {props.enableNotes && (
                      <Button
                        text="Add Note"
                        disabled={!isEditing || isGridReadOnly}
                        onClick={() => {
                          // Set grid editor modal mode
                          dispatch(
                            gridSlice.actions.setState({
                              type: props.type,
                              state: {
                                editorModalMode: "add-note",
                              },
                            }),
                          );
                          openModal();
                        }}
                        className="m-1"
                      />
                    )}
                    <span className="grid-config-label">
                      Grid configuration:
                    </span>
                    <span className="grid-config">
                      {userHasOverriddenGridState ? "Custom" : "Default"}
                    </span>
                    <Button
                      text="Reset"
                      onClick={resetGrid}
                      disabled={!userHasOverriddenGridState}
                      className="m-1"
                      variant="outline-secondary"
                    />
                    <Button
                      text="Clear Filters"
                      onClick={resetFilters}
                      disabled={!userHasOverriddenFilterState}
                      className="m-1"
                      variant="outline-secondary"
                    />
                    <Button
                      text="Refresh"
                      onClick={() => props.onRefreshClicked()}
                      className="m-1"
                    />
                    {isOpen && (
                      <GridRowEditorModal
                        onRowValueChanged={props.onRowValueChanged}
                        updateDataFields={props.updateDataFields}
                        isOpen={isOpen}
                        closeModal={closeModal}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="height-100">
                <Col
                  className="ag-theme-custom-react height-100"
                  style={{ width: "100%" }}
                >
                  <AgGridReact
                    ref={props.gridRef}
                    suppressColumnVirtualisation={
                      props.suppressColumnVirtualisation
                    }
                    columnDefs={
                      gridConfig?.namespace?.configuration?.[0]?.value
                        .configuration.columns
                    }
                    gridOptions={gridOptions}
                    rowData={props.data}
                    getRowId={getRowId}
                    onGridReady={onGridReady}
                    onColumnMoved={onColumnMoved}
                    onColumnPinned={onColumnPinned}
                    onColumnResized={onColumnResized}
                    onColumnRowGroupChanged={onColumnRowGroupChanged}
                    onColumnVisible={onColumnVisible}
                    onSortChanged={onSortChanged}
                    onFilterChanged={onFilterChanged}
                    onRowValueChanged={onRowValueChanged}
                    onRowSelected={onRowSelected}
                  />
                </Col>
              </Row>
            </Container>
          )))}
    </GridApiContext.Provider>
  );
};
