import { priceFormatter } from "../valueFormatters/priceFormatter";
import { numberCellStyle } from "../cellStyles/numberCellStyle";

export const priceAggregatedColumn = {
  type: "rightAligned",
  aggFunc: "avg",
  enableValue: true,
  valueFormatter: priceFormatter,
  cellStyle: numberCellStyle,
  filter: "agNumberColumnFilter",
  filterParams: {
    buttons: ["reset"],
    inRangeInclusive: true,
  },
};
