import { ColumnState } from "ag-grid-community";

export const serialiseColumnState = (
  gridColumnsState: ColumnState[],
): string | null => {
  const transformedColumnState = gridColumnsState?.map((columnState) => {
    if (!columnState.sort) {
      columnState.sortIndex = null;
    }
    return columnState;
  });

  return transformedColumnState ? JSON.stringify(transformedColumnState) : null;
};
