import { useEffect } from "react";
import Cookies from "js-cookie";
import { USER_IMPERSONATION_COOKIE_KEY } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../redux";
import { Selectors } from "../../redux/selectors";
import { authSlice } from "../../redux/slices/authSlice";
import { usePollingEffect } from "../hooks/usePollingEffect";
import { ImpersonationState } from "../../types/ImpersonationState";

export function WithImpersonationStatePolling({ children }: { children: any }) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authSlice.actions.setIsImpersonationSwitching(false));
  }, [dispatch]);

  const isUserImpersonating = useAppSelector((state) =>
    Selectors.isUserImpersonating(state),
  );

  const isImpersonationSwitching = useAppSelector((state) =>
    Selectors.isImpersonationSwitching(state),
  );

  usePollingEffect(
    async () => {
      if (isUserImpersonating !== undefined) {
        const impersonationRequestString =
          Cookies.get(USER_IMPERSONATION_COOKIE_KEY) ?? undefined;

        const impersonationRequest: ImpersonationState =
          impersonationRequestString
            ? JSON.parse(impersonationRequestString)
            : undefined;

        if (
          (impersonationRequest && !isUserImpersonating) ||
          (!impersonationRequest && isUserImpersonating)
        ) {
          window.location.assign("/");
        }
      }
    },
    [Cookies, isUserImpersonating],
    { interval: 1000, pollingSwitch: !isImpersonationSwitching },
  );

  return <>{children}</>;
}
