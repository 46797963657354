import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import { Error } from "./components/Error";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {
  defaultSettings,
  config,
  configUrl,
} from "./utils/configuration/settings";
import { Providers } from "./utils/Providers";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { App } from "./App";
import "./index.scss";

const app: ReactElement = (
  <ErrorBoundary>
    <BrowserRouter>
      <Providers>
        <App />
      </Providers>
    </BrowserRouter>
  </ErrorBoundary>
);

// Fetch configuration settings before rendering the React app
console.log(`Fetching configuration settings from ${configUrl}`);

const loadAppWithConfiguration = async (): Promise<ReactElement> => {
  try {
    const response = await fetch(configUrl);
    const settings = await response.json();

    config.set(settings);
    console.log(`Configuration settings loaded`);
    return app;
  } catch (error) {
    // In development, treat this case as a warning, render the app and use default config values.
    // In production (and test) on the other hand, show an error instead of rendering the app.
    if (process.env.NODE_ENV === "development") {
      console.warn(
        `Failed to load configuration settings from "${configUrl}", using the default settings instead.  See /public/settings.example.json for a sample settings file.`,
      );
      config.set(defaultSettings);
      return app;
    } else {
      const errorMessage = `Unable to load configuration settings from ${configUrl}`;
      console.error(errorMessage, error);
      return (
        <Error
          header="There was a problem fetching the app settings"
          errorMessage={errorMessage}
        />
      );
    }
  }
};

loadAppWithConfiguration().then((app: ReactElement) => {
  ReactDOM.render(
    <React.StrictMode>{app}</React.StrictMode>,
    document.getElementById("react-root"),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
