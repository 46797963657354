import { useState } from "react";
import Toast from "react-bootstrap/Toast";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface NewDataAvailableProps {
  onRefresh?: () => void;
  onClose?: () => void;
}

export const NewDataAvailableAlert = (
  props: NewDataAvailableProps,
): JSX.Element => {
  const [showError, setShowError] = useState(true);

  return (
    <div className="d-flex justify-content-center">
      <Toast
        show={showError}
        onClose={() => {
          setShowError(false);
          if (props.onClose) {
            props.onClose();
          }
        }}
        className="position-absolute"
        style={{ top: 80 }}
      >
        <Toast.Header>
          <strong className="me-auto">New Data Available</strong>
        </Toast.Header>
        <Toast.Body>
          <Container>
            <Row>
              <Col>
                New data is available for the data set you are currently
                viewing. Please click 'Refresh' to load this data.
              </Col>
            </Row>
            <Row className="mt-3 justify-content-center">
              <Col className="col-3 text-center">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowError(false);
                    if (props.onRefresh) {
                      props.onRefresh();
                    }
                  }}
                >
                  Refresh
                </a>
              </Col>
              <Col className="col-3 text-center">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowError(false);
                    if (props.onClose) {
                      props.onClose();
                    }
                  }}
                >
                  Close
                </a>
              </Col>
            </Row>
          </Container>
        </Toast.Body>
      </Toast>
    </div>
  );
};
