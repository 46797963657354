import type { ColDef } from "ag-grid-community";
import { FormSelectorField } from "./FormSelectorField";

const setToBlankValue = "C3-SetToBlank";

export const isValue = {
  defaultBlank: (value: any) => value === "",
  setToBlank: (value: any) => value === setToBlankValue,
};

export function GridRowEditorFormBody({
  columnDefsForEditingRow,
}: {
  columnDefsForEditingRow: ColDef[];
}) {
  const children = columnDefsForEditingRow.map((col) => {
    const isDropdown =
      Array.isArray(col.cellEditorParams.values) &&
      col.cellEditorParams.values.every((val: any) => typeof val === "string");

    if (!isDropdown) return null;

    const optionsFromServer = (col.cellEditorParams as { values: string[] })
      .values;
    const hasEmptyValueInServerOptions = optionsFromServer.some((val) => !val);

    const blankOptionForDefaultValue = { label: "", value: "" };
    const clientSideOptions: Array<string | typeof blankOptionForDefaultValue> =
      [blankOptionForDefaultValue, ...optionsFromServer];

    return (
      <FormSelectorField
        key={col.field}
        id={col.field}
        label={col.headerName}
        defaultValue=""
        options={clientSideOptions.map((value) => {
          if (typeof value === "object") return value;

          return !value
            ? {
                label: "<set to blank>",
                value: setToBlankValue,
              }
            : {
                label: value,
                value,
              };
        })}
        validate={(value) => {
          // if no value, we are fine
          if (!value) return true;

          // onChange triggers by a value-not-from-dropdown
          // will this even happen?
          if (
            value &&
            !clientSideOptions.includes(value) &&
            value !== setToBlankValue
          ) {
            return "Please select a value from the dropdown";
          }

          // as long as not empty, we happy
          if (value) return true;

          // empty value is allowed if server options contains empty value
          if (hasEmptyValueInServerOptions) return true;

          return "selection error";
        }}
      />
    );
  });

  return <>{children}</>;
}
