export function ModalSectionHeader({
  title,
  children,
}: {
  title: string;
  children: string;
}) {
  return (
    <>
      <h5>{title}</h5>
      <p>{children}</p>
    </>
  );
}
