import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APP_STATE_STORAGE_KEY } from "../../constants";

export interface UserState {
  email: string;
  organisationId: string;
}

export interface EmbeddedApplicationAuthenticationState {
  id: number;
  key: string;
  isAuthenticated: boolean;
  authenticationError?: string;
}

export interface AuthState {
  user: UserState;
  redirectToPath: string;
  embeddedApplicationAuthenticationState: EmbeddedApplicationAuthenticationState[];
  isUserImpersonating: boolean;
  isImpersonationSwitching: boolean;
}

// Load existing user state from local storage
const initialState: AuthState = JSON.parse(
  localStorage.getItem(APP_STATE_STORAGE_KEY),
)?.auth ?? { user: {}, embeddedApplicationAuthenticationState: [] };

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setUserOrganisationId: (
      state: AuthState,
      action: PayloadAction<string>,
    ) => {
      state.user.organisationId = action.payload;
    },
    setUserEmail: (state: AuthState, action: PayloadAction<string>) => {
      state.user.email = action.payload;
    },
    setEmbeddedApplicationAuthenticationState: (
      state: AuthState,
      action: PayloadAction<EmbeddedApplicationAuthenticationState>,
    ) => {
      if (action.payload) {
        console.log(
          `Logged in to ${action.payload.key}: ${action.payload.isAuthenticated}`,
        );
        // Find the embedded application using the key
        const index = state.embeddedApplicationAuthenticationState?.findIndex(
          (x) => x.id === action.payload.id,
        );

        if (index > -1) {
          // Copy the existing array to a new array
          const newState = [...state.embeddedApplicationAuthenticationState];

          // Update the embedded application state in the new array
          newState[index] = action.payload;

          // Update the state with the new array
          state.embeddedApplicationAuthenticationState = newState;
        } else {
          // Append the embedded application state
          if (Array.isArray(state.embeddedApplicationAuthenticationState)) {
            state.embeddedApplicationAuthenticationState = [
              ...state.embeddedApplicationAuthenticationState,
              action.payload,
            ];
          } else {
            state.embeddedApplicationAuthenticationState = [action.payload];
          }
        }
      }
    },
    setIsUserImpersonating: (
      state: AuthState,
      action: PayloadAction<boolean>,
    ) => {
      state.isUserImpersonating = action.payload;
    },
    setIsImpersonationSwitching: (
      state: AuthState,
      action: PayloadAction<boolean>,
    ) => {
      state.isImpersonationSwitching = action.payload;
    },
  },
});
