import { useContext } from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { NavigationContext } from "../context/NavigationContext";
import { config } from "../utils/configuration/settings";
import { constructUrlFromMenuItem } from "../utils/menu/constructUrlFromMenuItem";
import "./SubMenu.scss";

export const SubMenu = (): JSX.Element => {
  const settings = config.get();

  const navigationState = useContext(NavigationContext);
  const location = useLocation();

  return (
    <Nav className="sub-menu pt-2 pb-2" variant="pills">
      {navigationState.activeMenuItem?.items?.map((subMenuItem, menuIndex) => {
        return (
          <Nav.Item
            className="me-2"
            id={`sub-menu-item-${menuIndex}`}
            key={`sub-menu-item-${menuIndex}`}
          >
            <LinkContainer
              to={constructUrlFromMenuItem(
                subMenuItem,
                navigationState.activeMenuItem,
                settings,
              )}
              active={
                // Exclude the "/embed" route
                navigationState.activeMenuItem.route + subMenuItem.route ===
                location.pathname.replace("/embed", "")
              }
            >
              <Nav.Link
                disabled={
                  navigationState.activeMenuItem.disabled ||
                  subMenuItem.disabled
                }
              >
                {subMenuItem.text}
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
        );
      })}
    </Nav>
  );
};
