import logo from "../assets/images/logo_full_green.png";

interface LogoProps {
  width: number;
  height: number;
}

export const Logo = (props: LogoProps): JSX.Element => {
  return <img src={logo} alt="" width={props.width} height={props.height} />;
};
